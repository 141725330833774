import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/ganja-and-hess-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1973 vampire art film, Ganja and Hess"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 34</h1>
                    <h2>Ganja and Hess</h2>
                    <h3>February 12, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                    <h1>Transcript</h1>
                        <p><Link to="/episodes/ganja-and-hess">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:03.005)
                        You&#39;re listening to Bring Me the 99 Cent Rental. I&#39;m Brian White. I&#39;m one half of this morbid equation. I&#39;m joined by my cohost and actual brother, Dave White. Hey Dave, how you doing over there?</p>
                        <p>Dave! (00:11.27)
                        No, hi. Okay, you know, we&#39;ve, we&#39;ve been having some unseasonable weather lately, you know, like spikes in temperature. We&#39;re in time with the weather, because I&#39;ll tell you what happened. You know, I go out and I walk around a lot. And because of the warm, because of the warm weather, those fucking turkeys have returned to terrorizing Cambridge, Massachusetts. And</p>
                        <p>Bryan! (00:20.565)
                        We&#39;re going to talk about the weather again.</p>
                        <p>Bryan! (00:26.717)
                        Gotta get your steps in.</p>
                        <p>Bryan! (00:35.563)
                        Are they dragging their tal their talons across the cement?</p>
                        <p>Dave! (00:38.422)
                        They are mean as shit. And I don&#39;t know, maybe I told you this, I can&#39;t remember. But I read a thing last year, because we hit with a big problem with them here. And I can&#39;t imagine where they fucking live. This is a city. I don&#39;t know where you keep these giant birds. I&#39;m guessing some someone&#39;s yard is my guess. But I read a thing that was like, you can&#39;t back down to them. And you can&#39;t give in. So if like they&#39;re in the road, you kind of just have to like, keep driving towards them and like nudge them if you need to and they will move eventually. And I was like,</p>
                        <p>Bryan! (01:05.727)
                        Mm.</p>
                        <p>Dave! (01:08.138)
                        I just think it&#39;s great that we&#39;re supposed to deal with turkeys in the same way that like the FBI deals with kidnappers and terrorists. Like, I was because these things suck. I&#39;m hit with your car for all I care. They&#39;re terrible, but they are a menace to society.</p>
                        <p>Bryan! (01:21.669)
                        Yeah. Yeah, you can&#39;t you cannot compromise with terrorists or the terrorists when Yeah, man, that&#39;s that&#39;s, that&#39;s a strange phenomenon for a city like we got them up here all over the fucking place. But this is, you know, this is the country. So like, you expect to find them. But yeah.</p>
                        <p>Dave! (01:28.508)
                        Nope.</p>
                        <p>Dave! (01:38.85)
                        It&#39;s just bizarre. You come around a corner and there&#39;s this fucking giant bird just standing there and it&#39;s a giant bird that is like threatening you actively. You don&#39;t know me. Why are you so upset?</p>
                        <p>Bryan! (01:45.109)
                        Mm-hmm.</p>
                        <p>Bryan! (01:50.277)
                        Yeah, he&#39;s just there for the IPA&#39;s. So we practically grew up in the neighborhood videos, stores and the steady diet of undergarments that those shops provide us with continues automated to this day. There&#39;s no one else enjoy chopping it up with more about trashy movies and Dave. Just before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between episodes, you can also find us on Instagram at Bring Me the Axe Pod. Dave&#39;s over there at that Queer Wolf. We got a.</p>
                        <p>Dave! (01:52.344)
                        Ugh, fucking bastard.</p>
                        <p>Bryan! (02:14.197)
                        We&#39;ve got a sweet website now at bring me the axe.com. You can listen to all our past shows there and listen to the, or you can read the transcripts. You can listen to them. I don&#39;t know.</p>
                        <p>Dave! (02:23.731)
                        You can&#39;t listen to them. That is, it is in their nature to not be listened to.</p>
                        <p>Bryan! (02:27.761)
                        Yep. And if you don&#39;t know, we have another show called 99 cent rental, where we cover all manner of video store madness on the weeks that bring me the axes off. Our latest episode covering the baffling our latest episode covering the baffling 1982 slash or x ray is out now. And we&#39;ll be back next week with our first anniversary episode taking a deep dive into George Romero&#39;s magnum opus, Dawn of the Dead. You can also contact us directly at bring me the ax pod at gmail.com with any questions, comments or suggestions.</p>
                        <p>Dave! (02:38.668)
                        really.</p>
                        <p>Bryan! (02:56.541)
                        to let us know if there&#39;s a movie that you love and would like to hear us give at the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. We&#39;re on YouTube now. Search for us by name and subscribe there if you prefer to consume your podcast that way. You&#39;ll be doing us a big favor by leaving us a five-star review on Apple Podcasts, Spotify. So get, just do it. Do it. You know what? You can do it right now as you&#39;re listening to this, because I know some of y&#39;all, some of y&#39;all have done it. So if you&#39;ve done it, hey, thanks. If you haven&#39;t,</p>
                        <p>Dave! (03:14.445)
                        Oh, do it. Just do it.</p>
                        <p>It would make me, it would make me real happy.</p>
                        <p>Bryan! (03:26.717)
                        Do it and then I&#39;ll thank you because it&#39;s great. Yeah.</p>
                        <p>Dave! (03:28.234)
                        Yeah. I mean, look, we are children of the 80s. We did not get much attention or support at all. It means a lot when we. Yeah.</p>
                        <p>Bryan! (03:35.833)
                        Oh, I crave your anonymous attention. Yeah. So yeah. Also, if you listen on YouTube, do us a favor. Give the episode a like and leave a comment. We love hearing from you guys. So I get all that right out of the way, right at the top of the show. Dave, I got to tell you, I&#39;m a little intimidated by this one.</p>
                        <p>Dave! (03:57.267)
                        I understand there is a lot about this movie that is, there&#39;s a lot to think about, I guess. There&#39;s a lot to say and a lot to think about. But I&#39;m excited to talk about it, though, because I&#39;ve spent all fucking week just thinking about this movie.</p>
                        <p>Bryan! (04:17.937)
                        I have as well. Like, oh my god, I feel like I&#39;ve been researching it for like two weeks. I feel like I&#39;ve watched it several times. I&#39;ve watched it twice. But there is first of all, the we&#39;re two white guys talking about a movie that is not made for white people.</p>
                        <p>Dave! (04:32.438)
                        Mm.</p>
                        <p>Dave! (04:36.546)
                        it&#39;s not made for white people. It&#39;s definitely about black people or by a black experience. And it&#39;s hard as a white person watching this, no matter how well intentioned or no matter how hard we try to kind of access the material, it is still some of it is lost. And there is something very intimidating. But like, I want to talk about this movie. But I don&#39;t want to say the wrong thing or I want to misinterpret things. And you know, just sort of I don&#39;t want to offend anybody.</p>
                        <p>Bryan! (04:41.426)
                        Yes.</p>
                        <p>Dave! (05:04.97)
                        And I don&#39;t think either one of us is going to offend anybody.</p>
                        <p>Bryan! (05:08.193)
                        No, I feel like we tend to be fairly thoughtful and careful. I just I. Oh, it&#39;s going to be awesome when you do, but it was just like earlier this week. We did the whole what to watch double feature thing and we chose Blackula and I feel like I didn&#39;t quite communicate my intentions properly and we got some good responses to it, but we also got some that at the end of the week. I was like I.</p>
                        <p>Dave! (05:12.446)
                        I mean, if I&#39;m going to offend someone, I&#39;m going to do it on purpose.</p>
                        <p>Bryan! (05:36.061)
                        just don&#39;t know if I want to like put these out there in the public. So</p>
                        <p>Dave! (05:40.706)
                        Black Girl is a weird choice though, because it is an exploitation, well, it&#39;s a black exploitation movie that is like made by black director, black writer. So it&#39;s like, it&#39;s a, no, I don&#39;t want to say rarity, but I guess a kind of a rarity. Because if you do not know, black exploitation movies were mostly made by white people for black audiences, or white audiences, I guess. But, but it&#39;s a rare one in that it is made by black people. And so it&#39;s, it&#39;s pretty</p>
                        <p>stand out in that way. It&#39;s also a pretty good movie just generally.</p>
                        <p>Bryan! (06:11.709)
                        Yeah, that was because that was kind of my thing is like I posted it fully realizing that this is a black exploitation movie. But my intention was. This is I wanted to sort of present it as something else, as something other than what it kind of really is at the core, because it is a good movie. Like, I think that it&#39;s as good as like some of the best Christopher Lee dragons, you know, it&#39;s a really good movie. But like at the end of the week, I was like, I really kind of fucked up with that one.</p>
                        <p>And if I gotta clarify my intentions, I think I&#39;m doing it wrong. So hopefully, hopefully we get it right with this one. So as we&#39;re talking, this here is a special crossover event for Black History Month. We&#39;re going to take a look at a movie that falls appropriately and equally into both of our shows, Bring the Axe and 99 Cent Rental. So.</p>
                        <p>Dave! (06:59.39)
                        I would argue not quite equally, because I do not see this as a horror movie.</p>
                        <p>Bryan! (07:05.169)
                        Yes, yes, I will give you that.</p>
                        <p>Dave! (07:07.154)
                        I think it can be categorized that way, and it has been since it came out. I think that might actually be part of the problem with why it was not successful initially, and maybe why it&#39;s not successful now too a little bit.</p>
                        <p>Bryan! (07:18.613)
                        Mm. Yeah. So here&#39;s a taste. Give you a little taste of what we&#39;re going to do.</p>
                        <p>Dave! (08:25.622)
                        Man, let me tell you something. First of all, Sam Wayman&#39;s music for this is so fucking good.</p>
                        <p>Bryan! (08:30.853)
                        Un-fucking-real is a good base. So here, look, it&#39;s ganja and hassle, as though the episode title didn&#39;t give it away.</p>
                        <p>Dave! (08:37.002)
                        Yeah, the trailer doesn&#39;t, it&#39;s mostly visual, but if I had never seen this movie and I just saw that, I would be like, I don&#39;t know what that is, but I have to see that fucking movie right now.</p>
                        <p>Bryan! (08:46.181)
                        Yeah, yeah, this one appeared on my radar in the 90s. I think this sort of falls on its kind of missing period. You see.</p>
                        <p>Dave! (08:55.626)
                        Yeah, because it&#39;s only recently that it was like 2016 or something that it got fully restored. And it&#39;s a it&#39;s a rough restoration. I mean, they did the best. It&#39;s the fucking Museum of Modern Art. So it&#39;s the best of the best. But they did the best they could. But it looks like the source material was like dragged through the dirt.</p>
                        <p>Bryan! (09:00.508)
                        Yeah, yeah, but yet.</p>
                        <p>Bryan! (09:11.737)
                        Yeah, they didn&#39;t have much to work with because I believe the original camera negative was trashed when these guys recut it. Well, yeah, we&#39;ll talk about it. So yeah, spoilers before we get rolling. I don&#39;t know what, you know what it is? I&#39;m scared. I&#39;m scared, man.</p>
                        <p>Dave! (09:17.13)
                        Yep, because they just chopped it up. Oh yeah, we&#39;re getting ahead of ourselves here.</p>
                        <p>Dave! (09:23.658)
                        Yeah, you were. This is what you&#39;re going to get your head out your ass.</p>
                        <p>Bryan! (09:31.241)
                        A warning before we get rolling, we&#39;re basically going to talk about this movie from beginning to end, spoilers to follow. Usually I chide everybody, you know, you&#39;ve had a million years to watch this, but truth is you haven&#39;t. Its reappearance is something that&#39;s fairly recent, and it&#39;s still kind of a deep cut. So here&#39;s a little facts, a little something to know about this movie. Here was 1973. Here are Lauren. So here&#39;s some other movies that were released that year.</p>
                        <p>Dave! (09:55.032)
                        1973.</p>
                        <p>Bryan! (09:59.709)
                        The Satanic Rites of Dracula were released in 1973. Also, Jean Rolland&#39;s The Iron Rose. That&#39;s my favorite Rolland movie. Mm-hmm. I think it&#39;s...</p>
                        <p>Dave! (10:01.717)
                        Love it.</p>
                        <p>Dave! (10:06.998)
                        Don&#39;t love it. Really? I don&#39;t really have a favorite. I don&#39;t like his movies that much.</p>
                        <p>Bryan! (10:13.193)
                        Yeah, that&#39;s my favorite one. Also, 1973, Scream Blackula Scream, which is my favorite book. Best movie title. Let&#39;s see, also that year, the Gnarless tapes.</p>
                        <p>Dave! (10:19.786)
                        Also good. One of my all-time favorite titles of any movie.</p>
                        <p>Dave! (10:29.883)
                        Mm, speaking of deep cuts, that&#39;s one you can all skip if you can even find it. It is the precursor to Colchak. Yeah, it&#39;s too, it doesn&#39;t, it is completely humorless and the plot is muddy as fuck. It was a TV pilot, essentially. It&#39;s a two hour pilot. It&#39;s very not good.</p>
                        <p>Bryan! (10:33.858)
                        I haven&#39;t seen that one.</p>
                        <p>Bryan! (10:38.378)
                        Oh no shit. Alright.</p>
                        <p>Bryan! (10:45.521)
                        Yeah. So wait, did Dan Curtis have something to do with Colchak? Oh, I did not. I did not realize that.</p>
                        <p>Dave! (10:50.806)
                        Yes, he was the producer. I think he was the producer. He directed one of the movies, because the first two movies were written by Richard Matheson, I think he wrote both. I think Dan Curtis directed, he directed at least one of them, he might have directed both of them, but I don&#39;t think he did both.</p>
                        <p>Bryan! (11:01.129)
                        Yes.</p>
                        <p>Bryan! (11:05.437)
                        Huh. I was unaware of that. And lastly, for 1973, horror arises from the tomb. Yep. So yeah, cast and crew. The director is a guy by the name of Bill Gunn.</p>
                        <p>Dave! (11:12.275)
                        Never heard of it.</p>
                        <p>Dave! (11:20.062)
                        And you&#39;re gonna say, wait, who&#39;s Bill Gunn? And if I showed you a picture, you&#39;d be like, oh, he was on the Cosby show. And I would say, yes, he was, but only for two episodes. And for some reason, we all remember those two episodes.</p>
                        <p>Bryan! (11:25.028)
                        Yeah.</p>
                        <p>Bryan! (11:30.741)
                        Yep. So Bill Gunn was a novelist. He was a poet, playwright, an actor, a director. His primary medium was the stage. He I do believe he did. But he had been working in film as early as 1970 on a sort of surreal modern gothic called Stop for Warner Brothers.</p>
                        <p>Dave! (11:41.111)
                        He wanted Emmy, didn&#39;t he?</p>
                        <p>Dave! (11:53.23)
                        which I would love to see this. I don&#39;t believe the Warner Brothers has it. Apparently, there was like a VHS version that was released at some point. And I think they did a retrospective somewhere like 10 years ago or so. And they ran it. But Warner Brothers refuses to release it. And they say it&#39;s because of licensing things, which sounds like kind of bullshit. It&#39;s like if you really wanted to, you could you just get around it.</p>
                        <p>Bryan! (12:15.378)
                        Well.</p>
                        <p>Yeah, yeah. Well, I just think that the audience. Yeah, because Gunn is a real kind of footnote in film history in many ways, but also it sounds like the film&#39;s themes are fairly limited appeal to the wider audience. But it does sound like the whole reason that the movie.</p>
                        <p>Dave! (12:21.238)
                        Sounds like the content was a little iffy at the time and now it&#39;s just sorta like too late.</p>
                        <p>Dave! (12:39.019)
                        At the time, at least.</p>
                        <p>Bryan! (12:42.673)
                        of get shelved is among many reasons is there&#39;s something that happens like that Warner Brothers changes ownership like several times during the release or rather the production and release of the movie and just shit gets shuffled.</p>
                        <p>Dave! (12:56.958)
                        Yeah, and they say, so their claim is whoever, you know, whoever they are at Warner Brothers is like, oh, we lost some document and now we can&#39;t release it. It&#39;s like, that sounds like a pretty flimsy excuse, if you ask me, but whatever.</p>
                        <p>Bryan! (13:09.636)
                        Yeah. So yeah, but anyways, the movie ends up shelved, like we said, but Gunn is basically, he&#39;s still established as a name in Hollywood at a vital time in the development of new Hollywood.</p>
                        <p>Dave! (13:21.334)
                        because he was also one of the first black directors to be given a studio contract. He was like one of, they were like the five of them, maybe at the time. And I&#39;m gonna guess Cindy Poitier is probably one of them.</p>
                        <p>Bryan! (13:30.793)
                        Yes. Yeah, I can&#39;t remember. Yeah, probably. But yeah, he after stop he makes he makes this movie and then you know, he does a whole bunch of other shit. He was a very busy dude.</p>
                        <p>Dave! (13:43.806)
                        This one probably derailed his career, at least in terms of film. I mean, he is an incredibly talented man, but it&#39;s the response to this probably. Yes and no, I guess.</p>
                        <p>Bryan! (13:47.614)
                        for, uh.</p>
                        <p>Bryan! (13:51.241)
                        filmmaker. Yeah. So he&#39;s he dies in 1989, a year after his friend and star of this movie, Dwayne Jones dies. He dies of encephalitis. However, and this is a detail that I find quite egregious, because every fucking bio of this guy that I read says, Oh, he dies in 1989 of encephalitis. There&#39;s like one article that I read. And then there was another one that I read that kind of had it as a detail and corroborates it.</p>
                        <p>Dave! (14:01.474)
                        Hmm.</p>
                        <p>Dave! (14:20.714)
                        Listen, whenever someone dies young in the 80s.</p>
                        <p>Bryan! (14:20.733)
                        but it&#39;s yeah, the encephalitis was the result of AIDS complications and I.</p>
                        <p>Dave! (14:27.69)
                        Because, bum- Bill Gunn was either gay or bisexual, probably gay it sounds like.</p>
                        <p>Bryan! (14:35.601)
                        Yeah. But uh yeah, it kind of goes back to just like because this movie is this movie is fucking crazy and it talks. We&#39;re going to talk about it&#39;s a very dreamy some ambulant art movie that at its heart, I guess is supposed to be a vampire movie, but gun was really kind of</p>
                        <p>Dave! (14:53.102)
                        I&#39;ll set, I mean, I&#39;m gonna set it up in just a minute. When you&#39;re done with the facts, I&#39;ll set it up and contextualize it for you here a little bit.</p>
                        <p>Bryan! (14:59.081)
                        right? But yeah, but this movie, this movie talks about race, obviously, this movie talks about gender, this movie talks about class. It&#39;s it&#39;s, it&#39;s wild, it just all the shit that it that it approaches in a single movie. But sort of in the same way that when we did delivery boys, like, Ken Handler&#39;s, you know, a real creep, but</p>
                        <p>The same thing happened to him where like nobody would just kind of give him the dignity death of just kind of. Oh, I know, I know, but it&#39;s still just it&#39;s a fucking just nasty quality of it. When I found this detail out, like very late in my sort of research, I was like, what the fuck? But I mean, it makes sense. I get it. It&#39;s the fucking eighties and really at the fever pitch of.</p>
                        <p>Dave! (15:31.574)
                        Well, that&#39;s not uncommon at all. Unfortunately, it&#39;s deeply tragic.</p>
                        <p>Dave! (15:48.83)
                        The bigger problem is that we still can&#39;t talk about it. It&#39;s still just like, no, it&#39;s encephalitis. It&#39;s like, oh, for fuck&#39;s sake, it&#39;s 2024. Can we just be honest about some shit now?</p>
                        <p>Bryan! (15:58.045)
                        Yeah. But anyways, the cast, we&#39;ve got Dwayne Jones. It&#39;s a fucking crazy cast. Yeah. Dwayne Jones, one of the most recognizable faces in the history of horror. He played Ben in Night of the Living Dead. Jones, unfortunately, dies young in the 80s of a heart attack, but he led quite a life is Phil. Yes. Yeah. So he was very, very active. No, by this point.</p>
                        <p>Dave! (16:04.77)
                        Banger of a cast.</p>
                        <p>Dave! (16:18.314)
                        He was like a, he did a lot of stage acting, didn&#39;t he? Cause he wasn&#39;t really an actor by this point. He was a teacher?</p>
                        <p>Bryan! (16:27.485)
                        He was the head of literature at Antioch College, and he specializes in English lit and French lit. Yeah, which.</p>
                        <p>Dave! (16:33.482)
                        French, yeah. Cause he speaks French in this and it is like, it is fluid.</p>
                        <p>Bryan! (16:40.665)
                        Yeah, yeah. But yeah, remarkably sparse resume. But like we said, he was mostly a stage guy, very, very active in education. He had a whole actor&#39;s workshop of his own.</p>
                        <p>Dave! (16:49.982)
                        He strikes me, he always strikes me as like, he strikes me as someone who is like a reluctant actor. Like he does it because people ask him to, but it just doesn&#39;t seem like the kind of thing he really wants to do.</p>
                        <p>Bryan! (17:05.317)
                        I think that honestly, the impression that I get from him based on everything that I read about him was that he had a passion for education. And so teaching was really his thing and the theory of stage was really his thing and sort of like working with people who did wanna act. Yeah, but I mean, this really is kind of his thing. And this is weird, because up until this point, I had never, I always sort of just assumed that like Dwayne Jones vanishes.</p>
                        <p>Dave! (17:20.551)
                        Oh, a dramaturge.</p>
                        <p>Bryan! (17:34.145)
                        after this movie, but no, I mean, he&#39;s in a couple of other movies after this. He shows up in Beat Street, like, probably as a favor to somebody, like he said, but like, yeah, I mean, he was very, very busy and he was very, very active in higher education. Like, it&#39;s crazy. So, as his co-star, we&#39;ve got Marlene Clark, who was literally the poster girl for the movie Putney Swope.</p>
                        <p>Dave! (17:39.767)
                        Mm-hmm.</p>
                        <p>Dave! (17:56.578)
                        of Switchblade Sisters.</p>
                        <p>Bryan! (18:02.229)
                        grindy pictures like the shaft trip off slaughter. That one&#39;s actually really good. Switch Billion Sisters and Black Mamba. But then she comes into Bill Gunn&#39;s</p>
                        <p>Dave! (18:10.942)
                        I think there&#39;s a lag on your end.</p>
                        <p>Dave! (18:17.142)
                        I don&#39;t even just try and work with it, they just drive me fucking crazy.</p>
                        <p>Bryan! (18:17.685)
                        All right, well, I hope it&#39;s not.</p>
                        <p>All right. So where was I saying? Oh, yeah. She came into Bill Gunn&#39;s crosshairs on The Landlord, which Gunn wrote. It led to a lead role in his first picture, Stop, which we mentioned was Shell for numerous problems, but she makes a real impression in this movie. And it&#39;s no wonder they snatched her up again for Ganja. She was also Lamont&#39;s girlfriend on three seasons of Sanford and Son.</p>
                        <p>Dave! (18:47.536)
                        Yeah.</p>
                        <p>Bryan! (18:53.769)
                        So let&#39;s see, rounding out the cast is Sam Wayman. And he&#39;s not really an actor, though he&#39;s pretty capable in this movie. Like he really does hold his own. Yeah, he plays a minor but pivotal role in the movie as Dr. Hess&#39;s driver and the reverend of a church in the city. He is notable for being the brother of Nina Simone. And he also composes the movie&#39;s soundtrack, which plays a role in explaining what&#39;s happening at several key moments. Like the soundtrack is almost like a chorus.</p>
                        <p>Dave! (18:58.029)
                        Mm-hmm.</p>
                        <p>Dave! (19:01.75)
                        Yeah, he does a great job in this.</p>
                        <p>Bryan! (19:24.098)
                        They just like sing what&#39;s happening at certain times.</p>
                        <p>Bryan! (19:31.849)
                        See, is that a, is that delay really bad?</p>
                        <p>Dave! (19:34.514)
                        Yes, it&#39;s like several seconds.</p>
                        <p>Bryan! (19:40.102)
                        care for it.</p>
                        <p>Bryan! (19:46.697)
                        Give it a second.</p>
                        <p>Dave! (19:50.798)
                        because it was fine at first.</p>
                        <p>Bryan! (19:51.441)
                        What I can do to speed things up.</p>
                        <p>Dave! (19:54.87)
                        Like it wasn&#39;t there first. Now it&#39;s I notice it like, I don&#39;t know, five minutes ago.</p>
                        <p>Bryan! (20:05.533)
                        Don&#39;t care for it. Let me see if there&#39;s anything I can like shut off over here to speed things up.</p>
                        <p>Bryan! (20:21.596)
                        I can&#39;t turn that off.</p>
                        <p>Dave! (20:26.314)
                        Gotta get good internet.</p>
                        <p>Bryan! (20:30.75)
                        Yeah, my Internet&#39;s pretty good. Usually let me see what I&#39;m gonna run a speed test.</p>
                        <p>Bryan! (20:40.093)
                        Hmm. Now my, well, that&#39;s my download. My download&#39;s pretty good. Get everybody together, tell them to stop all the downloading.</p>
                        <p>Dave! (20:50.371)
                        Y&#39;all need to stop all this foolishness.</p>
                        <p>Bryan! (20:53.565)
                        Well, actually, my upload is pretty good too.</p>
                        <p>Dave! (20:56.686)
                        Maybe it&#39;s me. I don&#39;t think it&#39;s me. It&#39;s never usually me, but.</p>
                        <p>Bryan! (21:00.957)
                        Well, actually, it kind of sounds like...</p>
                        <p>Is there a delay now?</p>
                        <p>Dave! (21:08.205)
                        I don&#39;t know. Can you hear me?</p>
                        <p>Bryan! (21:09.861)
                        I can hear you just fine.</p>
                        <p>Dave! (21:11.698)
                        Alright, let&#39;s- we&#39;ll just- we&#39;ll just try and roll with it. Let me shut the- I&#39;ll close some of this other shit I have going on here, because I have a ton of stuff open.</p>
                        <p>Bryan! (21:13.832)
                        Oh.</p>
                        <p>Bryan! (21:20.349)
                        Yeah, usually when I do this, like I close all my windows, I shut down all the all this.</p>
                        <p>Dave! (21:22.062)
                        You want me to turn off the PlayStation and stuff? Yes, please.</p>
                        <p>Bryan! (21:25.981)
                        I shut all this off. I shut all my softwares off.</p>
                        <p>Dave! (21:29.906)
                        I turn on because I have all this shit open for the thing I&#39;m working on. But are you can you hear me in real time now? All right. All right. We&#39;ll just go with it.</p>
                        <p>Bryan! (21:36.273)
                        Yeah, yeah, you sound good. Okay, all right.</p>
                        <p>Bryan! (21:43.113)
                        Okay, so here&#39;s some notes. In 1972, okay.</p>
                        <p>Dave! (21:46.038)
                        Well, before we get into notes though, because I want to jump in and start with the contextualization, because I think it&#39;s really important to understand that because this is an abstract movie, and it&#39;s avant-garde in its way, I think it&#39;s important to think about where it came from. And so sort of like we did with Phantom of the Paradise, talking about why they kind of made this weird cynical movie in the early 70s, because this comes around the same time, and this is 73.</p>
                        <p>And so you get a lot of like, a lot of things are being influenced heavily by the civil rights movements that are happening at the time. One of those things is I guess what we would call now, like intersectionality. The the kind of belief that your identity is made up of lots of different things. It&#39;s not just one thing. So previously, people&#39;s identity was sort of monolithic in that they would have thought, well, I&#39;m American and that&#39;s just kind of who I am. And in the 70s, it&#39;s not.</p>
                        <p>directly because of civil rights, but that is a big part of it, is the civil rights movements. People start to think of themselves in these kind of hybrid identities. So you start to see things like Jewish American, Italian American, Black American. And that is a huge part of this, because I think what happens is, generally speaking, those are that&#39;s sort of an empowering thing. It&#39;s a liberating thing to start to celebrate different aspects of yourself.</p>
                        <p>that you&#39;d sort of maybe previously suppressed or minimized just in order to be a part of the bunch. But what happens is that a lot of people who are marginalized, those different pieces of identity are kind of directly at odds with each other in that, like, you know, if you&#39;re if you&#39;re a black American, you have these pieces where you&#39;re part of this culture that the sort of here and now active part of the culture that is</p>
                        <p>very much built on oppressing these other parts of you. And so you have like, you know, how can you participate in sort of a white dominated culture when that culture is, you know, responsible for sort of oppressing and marginalizing the other part of you, the sort of ancestral heritage, because that is going to be a huge part of this movie through and through, it comes up over and over again. Like it&#39;s, I guess,</p>
                        <p>Bryan! (24:10.641)
                        I&#39;d say it&#39;s the central theme.</p>
                        <p>Dave! (24:12.074)
                        Yeah, it&#39;s the main theme of the movie. And so, like, I think that contributes a little bit to why this doesn&#39;t succeed as a movie, because I was also thinking about what makes it like, how did this become a cult film? Part of it, I think, has to do with not accessibility, but availability, I guess, is that it&#39;s something people talk about it a lot, but because it got chopped up and sold as an exploitation movie. But...</p>
                        <p>Bryan! (24:34.645)
                        Availability for sure.</p>
                        <p>Dave! (24:41.462)
                        When it came out initially, I mean, if you read just basically, if you look at the Wikipedia thing and it&#39;s like, oh, when it came out, it was a very well reviewed. It&#39;s like, well, no, when it came out, it was a failure. It was a critical and commercial failure. And I think because in 1973, I&#39;m going to guess, I don&#39;t really know this for sure, but I&#39;m going to guess that critics in 1973 were probably no more equipped to engage with avant-garde film.</p>
                        <p>then white audiences were able to engage with black stories. And so if you go into this movie expecting a horror film or a black exploitation film or a vampire film, I think you&#39;re going to come away at, yeah, at best, you&#39;re going to be confused and disappointed because it&#39;s really none of those things. And it&#39;s really only within, you know, the last well, couple, maybe the last decade that</p>
                        <p>Bryan! (25:19.413)
                        You&#39;re going to be woefully disappointed.</p>
                        <p>Dave! (25:35.958)
                        maybe two decades, it&#39;s with the passage of time, sort of like, you know, I think most cult films takes a certain amount of time for it to build that audience. But the more people become familiar with experimental film or techniques of experimental filmmaking, and the more black stories start to break into the mainstream, so probably like late 80s, early 90s, mid 90s, maybe, then it starts to pick up that</p>
                        <p>that audience because people are starting to see it as something more than what it was originally seen as. But it&#39;s a hard movie to engage with because it is even beyond like thematically, it&#39;s challenging because it is such an intense theme. But aesthetically, it is it&#39;s rough. I mean, it&#39;s a nonlinear narrative that you don&#39;t even really notice is nonlinear half the time. But I do think that there&#39;s so many things about this movie.</p>
                        <p>So I think first of all, because as I was watching the whole thing, I was like, is all this noise, this sort of ambient background noise, is that intentional? Because it&#39;s very present. I think there&#39;s nothing about this that is not intentional. I think this movie is planned out meticulously. I may not get it, but...</p>
                        <p>Bryan! (26:41.673)
                        There is, yeah, there is a.</p>
                        <p>Bryan! (26:50.237)
                        with one of the most deliberate productions I think we&#39;ve done on the show at least. There is a musical leitmotif that runs through it that&#39;s actually in the trailer. It&#39;s that one of the, it&#39;s like a loop of children singing with a little.</p>
                        <p>Dave! (26:54.486)
                        Yeah.</p>
                        <p>Dave! (27:03.682)
                        Mm-hmm. And that shows up at the end in the weirdest way.</p>
                        <p>Bryan! (27:07.481)
                        It shows up during scenes of violence. It&#39;s it&#39;s almost like a kind of a musical cue for vampirism, but you can&#39;t understand what the kids are saying. It&#39;s just it&#39;s just a sort of a character in the movie. It&#39;s very unusual. But yeah, go on.</p>
                        <p>Dave! (27:10.647)
                        Mm-hmm.</p>
                        <p>Dave! (27:23.966)
                        And so I think that before, that&#39;s why I wanted to do this before we get into any of the content part is because I think there&#39;s a, you know, like all things, they have to kind of be understood in the era they were created, but especially something like this, because it is so much of its time. That is so much of, you know, this era in which people are starting to say, well, wait a minute, how can I be both of these things? Because what this movie is really about is someone trying to live.</p>
                        <p>within the tension of those two irreconcilable pieces of themselves that in the end of the movie can&#39;t be reconciled. They can only be reconciled through death, which is incredibly sad. But it&#39;s you can&#39;t understand the movie without understanding this part of history. And so that&#39;s why I wanted to kind of like shine a light on it first, because it does run throughout the whole thing, but also just understanding a little bit about Bill Gunn, too, because the character.</p>
                        <p>of Hess Green is basically just Bill Gunn. I mean, obviously he&#39;s not a vampire, but.</p>
                        <p>Bryan! (28:24.353)
                        Right, right, right. But yeah, everything that I&#39;d read about him was he was this kind of aloof, but very sophisticated dude. And uh-</p>
                        <p>Dave! (28:32.226)
                        I mean, he&#39;s a guy who is he&#39;s essentially like a kind of a critical success at a time when black artists were not given the sort of the attention from white critics or white audiences, he got so much of it. But he&#39;s also he has the success. And he&#39;s doing it trying to tell black stories and trying to talk about a black experience. And it&#39;s just that is so weirdly complicated.</p>
                        <p>And I think that&#39;s why you see a lot of the anger in this movie. You can see a lot of his frustration. And when we get to the reviews of it, we&#39;ll talk about I can tell you a little bit about that. But there&#39;s a there&#39;s just so much tension. It&#39;s just like it&#39;s all rooted in this one thing of like trying to be this whole person when parts of you are so constantly at odds with the other parts of you and you want to be all of them.</p>
                        <p>But how can you be all of them when they don&#39;t fit together and they will always be incongruous? Because his life is one that is, he&#39;s a very unclassifiable person, which is like one of the ways people talk about him a lot was like he was very hard to define because he was all of these things and these things don&#39;t go together, which is why I think this movie comes across this way.</p>
                        <p>Bryan! (29:36.254)
                        Mm-hmm.</p>
                        <p>Bryan! (29:44.453)
                        Yeah, there was.</p>
                        <p>Yeah, there was a lot of there were a lot of notes. Everything there was actually one at one of the sources that I&#39;ll cite at the end. Actually, it was like says that very thing that he was like a lot of things and he was not. But at the same time, he was not easily qualifiable because like he people did try to sort of stuff them into these kind of like emerging black art movements of the 70s, like the sort of the new African art movement and Afrofuturism and magical realism. And this movie is.</p>
                        <p>Dave! (30:13.866)
                        And that&#39;s in this. I mean, you see that throughout this.</p>
                        <p>Bryan! (30:17.725)
                        I was just saying, this movie is all three of those things, but also none of those things. It&#39;s fucking crazy. Yeah, so in 1972, Kelly Jones Enterprises approached Bill Gunn, who&#39;s primarily known for writing for the stage, with an idea for making a black vampire movie to capitalize on the wild success of Blackula. Now Gunn wasn&#39;t even a little interested in making a horror movie, which is pretty apparent when watching it. His original idea was to make a movie about addiction, and we somehow ended on this.</p>
                        <p>And it&#39;s actually a bit reductive to characterize the mission as a cash grab, as both Jones and Kelly worked with Josephine Baker and Maya Angelou on other projects and both held Bill Gunn in high esteem. But film is a business and Black Yilla was on the mind as the black exploitation market expanded this movie, however.</p>
                        <p>Dave! (31:04.414)
                        And he didn&#39;t even want to make a horror movie to begin with. I mean, he walked into it, they were like, hey, come make this horror movie. And he was like, no, thanks. I don&#39;t want to. I don&#39;t like horror.</p>
                        <p>Bryan! (31:12.849)
                        Yeah. But yeah, the bonus for him was that these two producers were pretty inexperienced in producing movies. So they just like, they just gave him however, however much money he needed. To a point, obviously, like this movie is limited by its budget pretty significantly. But he capitalized on that to basically do exactly what he wanted to do while still kind of working within the confines of their of the definition. This movie, however, not a black exploitation movie, not even a little.</p>
                        <p>Dave! (31:42.608)
                        No, it is. And this is also the single the vision of a single man. Like this is this is not a collective work. This is not a collaborative project. This is one man&#39;s vision for one story that he wrote.</p>
                        <p>Bryan! (31:55.005)
                        Yeah, yeah, and you get the feeling like I mean, there&#39;s credits and stuff, but this movie feels like it was made entirely by Bill Gunn, like he did the sound. He was director of photography. He did, you know, all this stuff. But like I mean, there was a crew on this movie. The film screened at Cannes. It was the only American movie selected that year for review reviewers week. And it screened to rave reviews in France. American critics didn&#39;t get it though. The producers were unable to market it.</p>
                        <p>to their intentions. They were expecting a black exploitation movie. What they got was a some nambulant art film. They sold the movie to a significantly sleazier releasing company where the movie were cut, removing 40 minutes of the actual camera negative and released in different markets under different titles like black evil and black vampire and</p>
                        <p>Dave! (32:43.422)
                        Which that is some dumb shit. Like, why would you eat? It doesn&#39;t matter what you&#39;re doing. Like, why would you cut the fucking master?</p>
                        <p>Bryan! (32:51.305)
                        because they were shitty companies who had no vision for the future beyond we need to capitalize on this right now. And it was probably cheaper to cut the fucking negative than it was to have it reproduced into something that you would edit on like a normal goddamn editor. They cut 40 minutes of this movie.</p>
                        <p>Dave! (33:08.05)
                        And I mean, that&#39;s why this that&#39;s why throughout this rematter this new transfer, some of it looks real rough because it&#39;s taken from like an old video cassette.</p>
                        <p>Bryan! (33:19.501)
                        Yeah, yeah. So yeah, Vampires of Harlem was another one, but it&#39;s most commonly known as Blood Couple in its cut form. Gun. Stupid. I cannot imagine what this movie looked like, even caught like, give it a goofy title as much as you like. Even slashing 40 minutes of the running time, like what the fuck was left over?</p>
                        <p>Dave! (33:28.394)
                        Which is a dumb as shit title.</p>
                        <p>Dave! (33:44.802)
                        God damn, that fucking lag is back. All right, I don&#39;t know what else I can close here.</p>
                        <p>Bryan! (33:48.122)
                        Ugh.</p>
                        <p>Dave! (33:55.918)
                        I don&#39;t think I have anything else running.</p>
                        <p>Bryan! (33:56.245)
                        Yeah, cause, uh, Nope.</p>
                        <p>Bryan! (34:03.024)
                        Let&#39;s run another speed test over here and see if it is me.</p>
                        <p>Bryan! (34:12.333)
                        Uploads. Uploads good. Downloads is good.</p>
                        <p>Dave! (34:15.802)
                        Check mine.</p>
                        <p>Bryan! (34:20.529)
                        My uploads is good.</p>
                        <p>Dave! (34:23.277)
                        Hmm mine is not it&#39;s probably me How do I chat away increase upload speed? How do I increase my upload speed?</p>
                        <p>Bryan! (34:27.942)
                        Nah.</p>
                        <p>Dave! (34:37.412)
                        because it&#39;s pathetic right now.</p>
                        <p>Bryan! (34:39.581)
                        How fast is your upload?</p>
                        <p>Dave! (34:41.682)
                        19 megabytes.</p>
                        <p>Bryan! (34:45.117)
                        That&#39;s actually that&#39;s about where mine&#39;s at. That&#39;s not bad. And all of a sudden, your picture just got real clear.</p>
                        <p>Dave! (34:49.838)
                        Oh, because my latency is 11. It says the server is in New York. Well, alright, fuck it, we&#39;ll just keep going I guess.</p>
                        <p>Bryan! (34:52.402)
                        Hmm.</p>
                        <p>Bryan! (34:57.425)
                        Yeah. Now all of a sudden, like your picture got real, real clear. And I think I think the lag has gone again.</p>
                        <p>Dave! (35:04.3)
                        Alright, let&#39;s do it.</p>
                        <p>Bryan! (35:06.347)
                        So Gunn was furious at this and he wrote an op-ed in the New York Times, one of the many major papers that trashed his movie. It&#39;s called To Be a Black Artist and from that article he writes,</p>
                        <p>Dave! (35:17.642)
                        Well, let me tell you, because we should say what some of these reviews are, because they&#39;re not. They&#39;re not just again, this has that feeling of a little bit of like how critics approach horror movies at the time, but they seem like a little bit, I don&#39;t know, more offended by it. So this is from Newsday and it says, from the very beginning, Ganja and Hess is so clumsy in elementary storytelling techniques.</p>
                        <p>Bryan! (35:21.061)
                        Okay, yeah, hit me with some of those reviews.</p>
                        <p>Dave! (35:44.286)
                        so murky and so circuitous, I thought it might belong in the same category as the honeymoon killers or Night of the Living Dead, pictures whose amateur look gave them added impact because they look like documentaries instead of horror shows. But Ganja and the Huss is merely clumsy and pretentious. And so here&#39;s New York Daily News. The plot is disjointed and odd camera angles, fantasy scenes, slow motion sequences and choppy editing make the film a</p>
                        <p>One can barely figure out what&#39;s going on. Dwayne Jones and Marlene Clark managed well in their title role, but their talents are wasted in an incredible film that was intended to be quality black oriented fair. Put a pin in that, because I&#39;m going to come back to that in a second. And then the New York Times was also sort of similarly dismissive. They call it a confusing, vague melange of symbolism, violence, and sex. Now, it is those things, but I don&#39;t think it is those things without reason.</p>
                        <p>So and that was the bulk of the reviews. They were basically like, oh, I thought this was going to be a sort of heady black movie and you gave me this. How dare you? And so rather than and this is why I wanted to kind of go back to that other stuff at the beginning is because if you walked into it expecting.</p>
                        <p>Bryan! (37:00.145)
                        It&#39;s, you know what it is? It&#39;s kind of like how we&#39;ve talked about the way that Gene Siskel talks about actresses and slasher movies where this, your product does not meet my expectations.</p>
                        <p>Dave! (37:07.159)
                        Yes.</p>
                        <p>Dave! (37:11.05)
                        Yeah, you did not live up to my expectations of what a black movie should be. And Marlene Clark has basically said the same thing. She was like, they wanted a particular kind of black movie. And when they didn&#39;t get it, they turned on Bill Gunn, they turned on her, they turned on Dwayne Jones. Because, you know, the it was never a possibility that it was something about them something about how they were viewing it. So rather than say,</p>
                        <p>Why am I not getting this movie? What am I missing from this film? They just assumed that it&#39;s because Bill Gunn was not a good filmmaker. Now, I don&#39;t think Bill Gunn is a very good filmmaker. I think he&#39;s a good stage director, probably. I think.</p>
                        <p>Bryan! (37:52.185)
                        This movie feels like it feels like a play. It&#39;s full of monologues. There&#39;s everything is sort of staged in a certain way. There&#39;s not a lot of like fluid continuity, which is which is part of the problem, the sort of narrative problem of the movie style. But if you think about it in the context of a stage production, it&#39;s all very common shit that you find in like any given play.</p>
                        <p>Dave! (37:56.583)
                        Yeah</p>
                        <p>Dave! (38:17.674)
                        Right. And I mean, that&#39;s the whole point of avant-garde cinema anyway, is to make you think differently about whatever the content is. I mean, if you think about something like, you know, fireworks or Scorpio rising, you don&#39;t know anything about those. If you just watch those movies without knowing anything about Kenneth Anger or without knowing anything about, you know, experimental film, you&#39;d be like, what the fuck am I watching? Because it&#39;s challenging you to think about something in a very different way, which is exactly what this movie is doing. And I don&#39;t know that it&#39;s always successful, but I think it&#39;s</p>
                        <p>mostly successful. But that&#39;s why I think his response, the letter he writes to the New York Times is really, really solid defense. And I think he makes a lot of really good points. So if I mean, I have read the whole thing, but I&#39;m not going to read the whole thing right now. But I do think the opening is very interesting. It&#39;s rather poignant.</p>
                        <p>Bryan! (39:09.477)
                        Is it, there are times when the white critic must sit down and listen. If he cannot listen and learn, then he must not concern himself with black creativity.</p>
                        <p>Dave! (39:13.677)
                        Yes.</p>
                        <p>Dave! (39:17.674)
                        Yes, because that I think is the that is a fair and astute argument to make. And that I mean, I think that really sums it all up is that if you&#39;re not willing to even try to comprehend what this is, then there&#39;s no point in you even sitting down to watch the movie. I mean, he said in one of I think it might be in this one where he&#39;s in the letter where he says, you know, someone watched a critic watched 20 minutes of this and then walked out and then reviewed the whole movie as though they&#39;d seen it.</p>
                        <p>And so it&#39;s like, if you can&#39;t even do that, if you can&#39;t even like try to understand a movie and just listen to what this is about, then why are you even bothering to watch it? Because I think that&#39;s that is, you know, I can watch this movie, and I have watched it a bunch of times. And not all of it makes sense to me. And not all of it is going to make sense to me. Because I&#39;m not a black man. But I kind of have to just sit there and say, Well, I&#39;m not going to get old, or I might not get any of it.</p>
                        <p>But I&#39;m still trying to think about what it is. And I, you know, that&#39;s, there is an issue of sort of error in that too. I mean, in 1973, critics for the New York Times thought they knew everything there was to know about film. I think a lot of critics still do think that. So there was no fucking way they were gonna be like, well, I guess I just don&#39;t get it. It&#39;s like, obviously the problem is Bill Gunn. It&#39;s like, no motherfucker, the problem is you. Cause you&#39;re an asshole.</p>
                        <p>Bryan! (40:27.207)
                        Oh, yeah.</p>
                        <p>Bryan! (40:37.029)
                        Yeah, yeah. I can certainly I can I can certainly understand like his reaction to this because like a part of a part of the I think part of the reason that this movie strike left me feeling so intimidated is because it is it is incredibly alienating but and some of it is very murky and very opaque but I think that the central like</p>
                        <p>like the whole thing that the movie hinges on is really sort of about like what you were talking about where it&#39;s there is this massive Gulf between the sort of cultural heritage of black people and sort of black people in America and there is this and we&#39;ll talk about when we get there I&#39;m sure but like the main character is this man who has kind of like a foot in both worlds.</p>
                        <p>But the one that he is trying most desperately to connect with is one that he is so far away that he just can&#39;t know it.</p>
                        <p>Dave! (41:38.11)
                        And even like other to the extent that other characters recognize that isolation from that world. I mean, I think that&#39;s the whole point of Bill Gunn&#39;s character is to point out how isolated, you know, Hess is from this thing that he is supposedly an expert in.</p>
                        <p>Bryan! (41:44.243)
                        Yeah.</p>
                        <p>Bryan! (41:54.053)
                        Yeah. So, available versions of this movie, particularly the copy available from Keno, is sourced from the one known surviving print that Gunn donated to MoMA. To put a little perspective on that, Vinegar Syndrome is gleefully restoring and scanning unbelievable garbage into stunning 4k presentations several times a month. It took a collaboration between the museum, Keno Lorber, and Martin Scorsese to bring this one movie back from the brink.</p>
                        <p>It is not a very good looking movie, but the Kino presentation is really good. Like that disc is very nice.</p>
                        <p>Dave! (42:26.286)
                        Uh-uh.</p>
                        <p>Dave! (42:29.858)
                        I mean, I think they did their best, though. Like they cleaned it up as best they could. It&#39;s you know, I have seen this one other time before this. It was probably 15 or so years ago. It looked like shit and it was like half of the fucking movie. So.</p>
                        <p>Bryan! (42:43.313)
                        Yeah, the bootlegs that used to kick around were not complete. Like they were more of those like weird fan composites. And I&#39;m not sure if the version that I saw like way back in the day was even close to the complete version that is out now. But yeah.</p>
                        <p>Dave! (42:59.646)
                        I think the bigger issue, or at least the bigger issue for me, was that the audio is a little sludgy.</p>
                        <p>Bryan! (43:04.749)
                        Yes, even yeah. So yeah, I was wondering because there were times because I listened to headphones on. And there are times when the audio sounds like super compressed, and it must just be like a process of upscaling or something like that trying to really try to polish a turn into you know, this sort of time in those terms, but it&#39;s the available version from Kino pretty good. If you&#39;re interested in this movie at all, like that&#39;s the version to get it&#39;s really it&#39;s really worth the worth exploring.</p>
                        <p>Dave! (43:20.791)
                        Yeah.</p>
                        <p>Dave! (43:30.75)
                        And unfortunately, when.</p>
                        <p>They released the score. God damn it. It&#39;s back again.</p>
                        <p>Bryan! (43:35.173)
                        So also one last thing, Spike Lee remade the movie in 2014 with Kickstarter money. I haven&#39;t seen it, but it looks like it&#39;s almost shot for shot with whole stretches of dialogue unchanged. And the title.</p>
                        <p>Dave! (43:46.09)
                        Alright, it&#39;s def- fuck. It&#39;s definitely me.</p>
                        <p>Is that, do we have other shit running? Everything says that it&#39;s like.</p>
                        <p>Dave! (43:59.938)
                        Alright, well fuck it, we&#39;ll do our best. God damn it. I&#39;m not gonna, I will not be happy with this and I will complain about it forever.</p>
                        <p>Bryan! (44:08.896)
                        The title, The Sweet Blood of Jesus.</p>
                        <p>Dave! (44:12.538)
                        Also a good title. I mean, it&#39;s not Scream Black Yellow Scream, but it&#39;s good.</p>
                        <p>Bryan! (44:13.781)
                        Pretty good title, I like it a lot.</p>
                        <p>Bryan! (44:18.397)
                        Pretty good title. Yep. So shall we get into it? Yep. All right. So the movie opens on a series of title cards that explain everything that&#39;s about to happen for some reason. Dr. Hess Green is a doctor of geology and anthropology. He is studying an ancient African race called the Murthians. He&#39;s also stabbed by a stranger using a dagger by the Murthian civilization whereby he becomes addicted to blood and cannot die or be killed. So now</p>
                        <p>Dave! (44:21.25)
                        Yep.</p>
                        <p>Dave! (44:45.104)
                        So this is where we&#39;re getting into that, we&#39;re starting out of order.</p>
                        <p>Bryan! (44:49.565)
                        Yes, which it&#39;s such a weird, weird detail because I have thought about this movie a lot and I cannot really understand the function of basically telling you what the movie is about and then showing you the movie.</p>
                        <p>Dave! (45:02.73)
                        wonder if it&#39;s a matter of, you know, like he is going into this knowing that this is going to he&#39;s going to be telling a nonlinear narrative that&#39;s going to be abstract the whole time. Maybe it will just be helpful to be like, hey, here&#39;s the fine points up front.</p>
                        <p>Bryan! (45:17.153)
                        Yeah, maybe. Because there is there are certain moments because like, there&#39;s no expositional dialogue in this movie. No, oh, God made us stabbed me with that Murphy and dagger, which was contaminated with the blood of those people. Now I&#39;m you know, like that&#39;s that whole thing. So I suppose that is sort of helpful. Like maybe he watched the movie after the final cut was like, maybe I should explain a few things just up front.</p>
                        <p>Dave! (45:39.434)
                        Yeah, because it&#39;s I mean, I and I, you know, I think it&#39;s I didn&#39;t find it jarring. I don&#39;t know that it was that helpful. But I do think it&#39;s interesting. The way that it&#39;s described in terms of sort of like Western Christian belief is really interesting. Because that&#39;s the whole I mean, throughout this whole thing, it&#39;s just going to be that verse, you know, Western beliefs and Western spirituality versus sort of, well, non Western, I guess, for lack of a less precise term.</p>
                        <p>Bryan! (45:45.158)
                        No.</p>
                        <p>Bryan! (45:55.741)
                        Yeah, yeah, because again.</p>
                        <p>Bryan! (46:07.773)
                        Yeah. So, uh, we, this movie is, is divided into three parts. Part one is called The Victim.</p>
                        <p>Dave! (46:15.67)
                        Don&#39;t get used to that, because we&#39;re just going to abandon that shit halfway through.</p>
                        <p>Bryan! (46:19.537)
                        No, it does end on after part three is like is when they start to sort of wind down the story. So we cut to a church service overseen by Reverend Luther Williams as who&#39;s played by Sam Wayman as. He&#39;s really good.</p>
                        <p>Dave! (46:32.898)
                        who is surprisingly good in this role. I mean, I get that he&#39;s not an actor, but he&#39;s really fucking good. It helps that he feels very much of the time. He&#39;s a really good looking man. I mean, he&#39;s tall, he&#39;s very alive in the role. And he was the one I think I liked watching the most.</p>
                        <p>Bryan! (46:52.493)
                        He&#39;s very animated and there is something very so he plays this. I don&#39;t they don&#39;t really make much hay over the denomination, but he&#39;s a preacher in a church. And it is it is one of the ones who&#39;s a lot of singing a lot of dancing. It&#39;s it&#39;s very animated. It&#39;s very colorful. He must have spent a lot of time in those actual churches because the way that he portrays that character is like super authentic. It&#39;s just the way that he talks. It&#39;s so cool. It&#39;s so cool.</p>
                        <p>Dave! (47:02.326)
                        Yeah, it&#39;s a Western black church. I mean.</p>
                        <p>Dave! (47:18.091)
                        Yeah.</p>
                        <p>Bryan! (47:22.581)
                        But there&#39;s also there&#39;s a voiceover also, and it&#39;s Wayman who explains that he&#39;s also a show-for to a man named Dr. Hess Green. And Williams explains that Hess is addicted to blood over a scene of someone injured in a car accident while Hess looks on dispassionately.</p>
                        <p>Dave! (47:38.206)
                        And again, some really interesting stuff happening here because this is at a time when you&#39;re starting to see the rise of that of the megachurch and of televangelism. And so to kind of contrast that with this black preacher who has to work not one, but two extra jobs in order to make ends meet is a very interesting point that they don&#39;t really come back to. But just it&#39;s interesting to say like this is his job, but he also is a chauffeur and he works in the stables as well.</p>
                        <p>Bryan! (48:06.781)
                        That&#39;s right.</p>
                        <p>Dave! (48:08.082)
                        I also think it&#39;s interesting to call Dr. Green a victim is a very interesting choice of words. Interesting is the way I would describe this whole fucking movie. Because it is in my notes like a thousand times.</p>
                        <p>Bryan! (48:17.661)
                        Yeah, yeah.</p>
                        <p>Yeah, so at a museum, Hess is introduced to a man named George Maeda, who&#39;s played by William Gunn, who will be his new research assistant. As this is happening, Maeda is shown posing in a mirror with a revolver. As they leave, Wayman sings about the Myrthians and explains that the only way to kill them was for them to stand in the shadow of the cross and that they lived unable to die until the Christians came.</p>
                        <p>There&#39;s a thing about that name, the Murthians, it&#39;s not spelled the same, but there is a certain illusion to the actual word of mirth, like sheer good times and stuff like this. It&#39;s alluded to that this race, which is not real. They existed like 10,000 years before the Egyptians.</p>
                        <p>Dave! (49:08.03)
                        And their queen is played by Eveline from The Wiz.</p>
                        <p>Bryan! (49:11.389)
                        That&#39;s right. Yeah. So, uh</p>
                        <p>Dave! (49:13.914)
                        In like the kind of Ur-Cameo role.</p>
                        <p>Bryan! (49:17.833)
                        Yeah, but this kind of establishes sort of that point of the sort of one side of this kind of vast gulf that Hess is going to try and sort of bridge this whole movie. But the fact that they are so old is to sort of place them on a timeline that is just unreachable. There was no written record. There&#39;s no way to really know them. Truly. They are a concept in his mind.</p>
                        <p>Dave! (49:46.238)
                        And I think that&#39;s a really interesting point within the context of this, and just generally, is that because this is also at a time, like I said, when people are sort of exploring different facets of identity. And, you know, one of those is this, you know, black Americans starting to look back at their ancestral history and their cultural heritage, particularly outside of the United States. And it&#39;s such a different thing to, you know, to be.</p>
                        <p>a person living say in Kenya, you know, you have a particular experience with the culture there, but to be a black American trying to access this thing that you have no direct connection to. And so that there is that probably that feeling of like, I, I am connected to this, but at the same time, I&#39;m not because I&#39;m also more connected to this, this thing which in this, in this metaphor is framed as, and it is quite literally a colonizing thing that is there to destroy this thing you&#39;re trying to access.</p>
                        <p>It must be a very tough thing to be doing that in the moment, because now we&#39;re, you know, we&#39;re several decades from that, removed from that, and people have a more nuanced understanding of that. But at the time, it was just like, oh, but isn&#39;t it just the same? And it&#39;s like, well, no, it&#39;s not the same at all. And so.</p>
                        <p>Bryan! (51:03.281)
                        Yeah. The main point here in this scene is to sort of plant a flag in a very central theme of the movie that&#39;s going to keep coming up over and over and over again. The arrival of Christianity killed the Merthians and Christianity in this in this sense.</p>
                        <p>Dave! (51:18.762)
                        Now hey, I don&#39;t wanna be anti-religious here, but god damn it. These fucking people.</p>
                        <p>Bryan! (51:26.738)
                        Well, I mean, hey, in this movie, they symbolize a conquering cultural colonizing force, whose very symbol whose very symbol destroys an entire race of African people. It&#39;s fucking nuts. So now as he did it again, and this is coming from two people, two white dudes whose last name is white.</p>
                        <p>Dave! (51:34.186)
                        Yeah, because that&#39;s what they were.</p>
                        <p>Dave! (51:44.574)
                        Yeah, way to go, white people. You did it again.</p>
                        <p>Bryan! (51:54.213)
                        Yeah, so as they arrive at Hess&#39;s manor, Maeda explains that he prefers to be called Maeda because he hates the name George.</p>
                        <p>Dave! (52:01.95)
                        Now I have to ask here in this moment, how much of the dialogue, at least in terms of Bill Gunn, how much of the dialogue do you think is ad-libbed?</p>
                        <p>Bryan! (52:12.805)
                        A lot of it. I would say a lot of it, particularly his big sort of monologue that&#39;s coming up and definitely Marlene Clark&#39;s story of the guy who smuggled weed. They feel they feel very monologue workshopped. But so much of this movie. Also the scene of him when he&#39;s in the tree talking to Hess, again, feels really, really</p>
                        <p>Dave! (52:24.52)
                        Mm-hmm.</p>
                        <p>Dave! (52:37.271)
                        Yep.</p>
                        <p>Bryan! (52:41.058)
                        ad libbed and just improvised on the spot.</p>
                        <p>Dave! (52:43.35)
                        But also I think it&#39;s important because they&#39;re not, the dialogue is not that important to the movie. Because so much of it is just rambling. Or at least so much of the George Mayda stuff is rambling.</p>
                        <p>Bryan! (52:53.349)
                        Yeah, there. Yeah, most of the well, there&#39;s a reason for that, but. Most of the action in this movie, the scenery that kind of is telling the story is people just not miming, but it&#39;s just silent action. People just doing things while the camera looks on. Yeah. So now over dinner in a scene like something out of my dinner with Andre, made it tells a story to Hess, who looks like he could not give a fuck.</p>
                        <p>Dave! (53:10.164)
                        Mm-hmm.</p>
                        <p>Bryan! (53:21.369)
                        about a friend of his who made a movie in the Netherlands where the word cut is the word for cunt there. The takeaway from this scene is that Meda is not all together with it.</p>
                        <p>Dave! (53:31.99)
                        But also at the same time, you have the character, is it Archie? Is that his name?</p>
                        <p>Bryan! (53:37.733)
                        Archie is the butler, yeah.</p>
                        <p>Dave! (53:38.574)
                        It&#39;s like the butler, I guess, who just keeps coming in and coming out and coming in and coming out. He keeps interrupting them in this way that makes the story feel like you get to a point where like Jesus Christ, just fucking spit it out. Because every time Archie comes in, he stops and he&#39;s, you know, he says something to him or he thanks him. And it&#39;s very interesting. But it&#39;s just like, again, it&#39;s that feeling of inaccessibility.</p>
                        <p>Bryan! (53:59.985)
                        Yeah, but what&#39;s weird is later on when Ganja comes into the picture, they&#39;re going to do something very similar. The scene is almost is almost identical, but she treats Archie way different.</p>
                        <p>Dave! (54:06.489)
                        Oh yes.</p>
                        <p>Dave! (54:12.082)
                        And it is super uncomfortable. But he says, so George says something really interesting in this, in this sort of monologue thing about the story. He kind of gets to the end and he says, if I understood desire, I wouldn&#39;t be so afraid of it. Which I think is probably the greatest line of the movie.</p>
                        <p>Bryan! (54:15.421)
                        Yeah. So.</p>
                        <p>Bryan! (54:29.405)
                        That&#39;s a fucking heavy line. Yeah. So later, Hess handles a bone with all these tribal carvings in it, having a dream of a Murthian queen in a feathered headdress, posed by scenery of the director of the museum that he you know that he works for. And both of them are extending a hand toward him.</p>
                        <p>And when he wakes, he finds Maeda outside, just sitting in a tree from which a noose hangs. Yeah, and Maeda is suicidal, you see.</p>
                        <p>Dave! (54:57.182)
                        Also a great, great scene.</p>
                        <p>Visually, it is upsetting though, because you have a man, you have two black men, one of them sitting in the tree, and there&#39;s a noose, and it is just, it&#39;s very dark, it&#39;s very stark imagery.</p>
                        <p>Bryan! (55:12.913)
                        Yeah, and you only see made his feet. Yeah, so yeah, he&#39;s suicidal and when has gets him back inside made it explains himself in manic fashion with snot running down his face. And then we learned that this is kind of like he has a history of suicidal behavior.</p>
                        <p>Dave! (55:31.518)
                        Now, the thing I wasn&#39;t sure about is, is George made a, does he also have this affliction?</p>
                        <p>Bryan! (55:43.121)
                        No, because he shoots himself and he dies.</p>
                        <p>Dave! (55:45.43)
                        He dies, but he also dies in front of the cross.</p>
                        <p>Bryan! (55:51.077)
                        Ooh, that&#39;s a good question.</p>
                        <p>Dave! (55:53.238)
                        because he says he&#39;s tried to kill himself before and it hasn&#39;t worked.</p>
                        <p>Bryan! (55:59.661)
                        shit. I hadn&#39;t even thought of that. Like, that&#39;s a good question. That&#39;s a very good question. And it&#39;s a good point. And I would say</p>
                        <p>Dave! (56:05.366)
                        Because I&#39;ll bring up more when we get to it, because I think there&#39;s something more to I have more thoughts on it. But it makes a lot more sense if he is. And they never say one way or another, but it makes a lot more sense because it feels like has he been driven crazy by this sort of tension of trying to live in one world and pursue another?</p>
                        <p>Bryan! (56:28.017)
                        I think that&#39;s kind of what&#39;s at play here is he</p>
                        <p>Bryan! (56:34.349)
                        he&#39;s struggling in the sort of same arena that Hasse is going to eventually move into, and eventually he succumbs to the same thing that Hasse is going to succumb to, which is this</p>
                        <p>Bryan! (56:47.339)
                        just hopelessness that</p>
                        <p>Dave! (56:49.162)
                        Yeah, because I think my interpretation and it&#39;s coming up, I&#39;m just going to say it now, though. My interpretation is when he does kill himself, it is in this sort of way of saying, if you want to access this world here, this is how you can access it. And he kind of gives himself to do that because he does stab him with the dagger first, then shoots himself.</p>
                        <p>Bryan! (57:12.401)
                        Yeah. So, yeah, later on, Maeda attacks Hess with an axe as he sleeps, but he wakes up and they fight, and as they struggle, Maeda stabs, he grabs the Murthian dagger, that bone thing, and he stabs Hess with it three times, presumably killing him. Now, Maeda then...</p>
                        <p>Dave! (57:33.843)
                        This scene is also very, it&#39;s very homoerotic in a way, in the way that it&#39;s choreographed.</p>
                        <p>Bryan! (57:38.973)
                        There is. There is a I was one of the things I was saying is like the beginning, like I said, this is about race, it&#39;s about gender, it&#39;s about class, the one thing that it doesn&#39;t really touch on is sexuality at all. But there is a weird sexual tension between made and has. Yeah, and, but it&#39;s not as deliberate as the rest of it. I think it may have just kind of come out that way. But yeah, so</p>
                        <p>Dave! (57:57.934)
                        Mm-hmm.</p>
                        <p>Dave! (58:05.151)
                        Mm.</p>
                        <p>Bryan! (58:08.633)
                        Mayda then types out a very poetic metaphor.</p>
                        <p>Dave! (58:09.418)
                        Oh, there&#39;s also no blood. There&#39;s no blood when he stabs Hess. There is on the wall, but there&#39;s no blood on the knife and there&#39;s no blood on Hess.</p>
                        <p>Bryan! (58:19.941)
                        right right. Because there&#39;s blood in the movie. I wonder. Yeah, like I wonder if that I mean that must have been like I said another deliberate thing. That&#39;s such a small detail. Anyway, made a typeset of poetic manifesto and he takes a bath and then he shoots himself but has is not dead as we thought he was.</p>
                        <p>Dave! (58:25.633)
                        Oh, there&#39;s tons of it.</p>
                        <p>Dave! (58:44.198)
                        The bath scene is super gross because he&#39;s sitting in the breath, but he&#39;s brushing his teeth at the same time and he keeps dipping the toothbrush in the water. And then he starts gargling with the water. And it&#39;s like, dude, is bath water is like. It&#39;s fucking it&#39;s so gross. But the scene where he shoots himself is so.</p>
                        <p>Bryan! (58:49.79)
                        Yes, it is.</p>
                        <p>Bryan! (59:01.199)
                        It&#39;s all murky and shit. There&#39;s like stuff floating in it.</p>
                        <p>Yeah.</p>
                        <p>Dave! (59:09.318)
                        It&#39;s a stunning moment in the movie. I think it&#39;s one of the better moments of the movie. It&#39;s a very it&#39;s a very monologue heavy film, which again, I think that&#39;s that stage experience because Gunn&#39;s experience as a filmmaker was very limited at the time. And so a lot of this feels like it would probably work better on a stage. But this part in the in the bathroom is so fascinating because he&#39;s really he&#39;s fucking playing it to the hilt. But he also they they.</p>
                        <p>When he does shoot himself, it&#39;s sort of they keep cutting back and forth between the sort of religious imagery. So it&#39;s like of made his body and this religious like the imagery of Christ. And I again, this could just be like either reading too much into it, but it sort of has this feeling that he is giving his life so that Hess can then go and, you know, explore this thing that has driven essentially driven him crazy.</p>
                        <p>Bryan! (01:00:07.729)
                        Yeah. There&#39;s also no, but there&#39;s also a lot of allusions to Jesus in this and a lot of the sacrifice in order to sort of like redeem the some quality, you know, and I there&#39;s a little bit of that. It makes me wonder if this is kind of what</p>
                        <p>Dave! (01:00:07.942)
                        It&#39;s I don&#39;t and they don&#39;t say one way or another</p>
                        <p>Bryan! (01:00:34.349)
                        made his death was supposed to be because he kind of like he willingly goes to his death in the same way that like Christ was supposed to have</p>
                        <p>Dave! (01:00:38.678)
                        That&#39;s how I read it. I read it as this is him sacrificing himself for Heskreen, essentially.</p>
                        <p>Bryan! (01:00:45.905)
                        Yeah. So out in a field maybe later or actually I forgot the one the one detail. So so Hess is fine. He looks himself over and he finds no wounds on him. He finds made his body, however, and he is overcome with hunger and he drinks his blood off of the floor. So now out in a field maybe later maybe in his imagination. It&#39;s honestly not clear. Hess praise</p>
                        <p>and then screams in horror.</p>
                        <p>Dave! (01:01:16.65)
                        And it&#39;s also the field where he keeps having that vision of the sort of Queen of Murthia and passing through. A lot of things happen in the field that he is running through, which also happens to be the field behind his house.</p>
                        <p>Bryan! (01:01:30.909)
                        Yeah, we&#39;re gonna keep coming to that field. It&#39;s...</p>
                        <p>Bryan! (01:01:36.581)
                        I&#39;m not really sure what it&#39;s symbolic of, but it plays a large role here.</p>
                        <p>Dave! (01:01:39.11)
                        I think it might just be a limitation of the film making process. Like they rented this house. They shot most of it at this house. This was the backyard of that house.</p>
                        <p>Bryan! (01:01:44.924)
                        Yeah, I suppose.</p>
                        <p>Bryan! (01:01:52.073)
                        So part two is called Survival. Hess causes a distraction at a doctor&#39;s office by exploding something in a garbage can. And as everybody, which, that scene is, yeah.</p>
                        <p>Dave! (01:01:59.414)
                        Yeah, what the fuck? I think it&#39;s just firecrackers, but he just like, I don&#39;t know, it feels like a stagecraft.</p>
                        <p>Bryan! (01:02:08.057)
                        Yeah, as everybody runs outside, Hess sneaks into an office, steals several bags of blood before fleeing on foot. Outside, we watch Hess walk away on a long single shot when the camera becomes fixated on a bus, bearing the word liberation on the back as it drives away. Now at a garden party at Hess&#39;s place, he is reunited with his son, a young boy who he seems to keep at a distance.</p>
                        <p>Dave! (01:02:29.614)
                        Okay, we gotta stop because his son, what? Where does this come from?</p>
                        <p>Bryan! (01:02:38.917)
                        I know because well the whole thing is like what there are details of this of this story like we never we never hear of a wife. He lives alone. He lives very much alone. His isolation is a central piece of his character and he lives in a very sort of European existence. It&#39;s lots of like fine food and he speaks French fluently and he lives in a</p>
                        <p>Dave! (01:02:39.743)
                        Is it a boy?</p>
                        <p>Dave! (01:03:03.87)
                        I mean, surrounded by white antiquity.</p>
                        <p>Bryan! (01:03:08.017)
                        He lives in a Tudor mansion. He drives, he has a couple of really fancy European cars. Like it&#39;s, that&#39;s like, that&#39;s his life. But like his house is also adorned with like artifacts of this Murthian culture. But also apart from this party, and until really the arrival of Ganja, he is super alone. Well, you know, way that is, oh, with his help. Yeah, and Archie.</p>
                        <p>Dave! (01:03:29.654)
                        with the exception of Sam Wayman&#39;s character.</p>
                        <p>Dave! (01:03:36.21)
                        he does seem to have very close relationships with both of them. He does seem to appreciate them and respect them in ways that other people in the movie don&#39;t.</p>
                        <p>Bryan! (01:03:36.849)
                        But yeah, but you.</p>
                        <p>Bryan! (01:03:45.701)
                        Yeah. So yeah, garden parties reunited with the sun young boy seems to keep at a distance the way they speak to one another suggests that they don&#39;t see each other often. Also they speak French to one another.</p>
                        <p>Dave! (01:03:57.458)
                        Now, I here&#39;s the thing that I love that not just in this movie, but I love when they do this in movies in general is when they&#39;re speaking another language and they don&#39;t offer subtitles or translations. I fucking love that. I love it in every movie in general, just because I&#39;m like, I like that feeling. I like listening to other languages anyway, but I like feeling like what are they saying? I have no idea. And that&#39;s the point of again, it&#39;s that inaccessibility that happens in this movie. You don&#39;t know what they&#39;re saying. And that&#39;s why I like.</p>
                        <p>Bryan! (01:04:06.493)
                        They don&#39;t sub. They don&#39;t give you the subtitles.</p>
                        <p>Dave! (01:04:25.558)
                        You know, at the beginning, when he&#39;s talking to madea, you can&#39;t hear them because they keep getting interrupted. There&#39;s so much fucking background noise of shoes and people and food and whatever. This is another example of like you&#39;re this is inaccessible to you. You don&#39;t know what they&#39;re talking about unless you speak French, of course. But let&#39;s be honest. How many white Americans, you know?</p>
                        <p>Bryan! (01:04:47.538)
                        I do know that they discuss his son&#39;s Swiss Army knife at one point. That&#39;s that because I picked up I picked up that little that little bit.</p>
                        <p>Dave! (01:04:51.352)
                        Yes.</p>
                        <p>Dave! (01:04:55.21)
                        What I think is fascinating about this, this part of this movie is that he cuts, they cut from here. He&#39;s at this sort of fancy, you know, everybody else is white at this party. It&#39;s obviously like a fundraiser or they&#39;re like, it&#39;s the other people from whatever institution he works for. And they cut from here to him going to just sort of a bar like a, you know, in like it&#39;s obviously a bar.</p>
                        <p>Bryan! (01:05:08.019)
                        Yeah.</p>
                        <p>Bryan! (01:05:21.403)
                        Yeah.</p>
                        <p>Dave! (01:05:22.726)
                        in, I&#39;m going to guess, a black part of town. It&#39;s just, it&#39;s so interesting that he goes from this party full of sort of white, privileged white people, and he goes then to this black bar where he doesn&#39;t fit in. And this is when he kills somebody.</p>
                        <p>Bryan! (01:05:44.177)
                        Yeah, so he goes to well he goes there, I believe deliberately to find somebody to kill. Because like</p>
                        <p>Dave! (01:05:49.822)
                        Yes, and that&#39;s what I think is interesting is that he&#39;s going there. He leaves this white upper class white party and goes to a sort of working class black bar where he finds a victim to kill, because later on he&#39;s going to do the opposite of that.</p>
                        <p>Bryan! (01:06:03.453)
                        Yeah, his whole his entire intention in this in this moment is to literally feed on the underclasses</p>
                        <p>Dave! (01:06:09.847)
                        Yes.</p>
                        <p>Dave! (01:06:13.546)
                        And there are moments too in this moment where... Am I... Can you hear me now?</p>
                        <p>Bryan! (01:06:15.962)
                        I lose you again.</p>
                        <p>Dave! (01:06:20.39)
                        Son of a bitch. All right, do you wanna start over or?</p>
                        <p>Bryan! (01:06:20.837)
                        I lost your audio.</p>
                        <p>Dave! (01:06:29.104)
                        Grrrr!</p>
                        <p>Dave! (01:06:33.418)
                        Have a now.</p>
                        <p>Nothing.</p>
                        <p>Dave! (01:06:40.61)
                        Well, shit.</p>
                        <p>Bryan! (01:06:43.509)
                        Get out of here.</p>
                        <p>Bryan! (01:06:47.045)
                        I heard you as I was talking about that, I will run like the last thing I said, I heard you cut out.</p>
                        <p>Dave! (01:06:54.67)
                        Bye for now.</p>
                        <p>Bryan! (01:07:06.343)
                        I got you now. I got you.</p>
                        <p>Dave! (01:07:08.022)
                        Do you want me to try to back out and come back in?</p>
                        <p>Bryan! (01:07:12.501)
                        No, I think I think you&#39;re fine because I could every time that you were talking, I could see a little meter moving that indicated it was receiving sound. So it&#39;s I don&#39;t know if this is this is frustrating.</p>
                        <p>Dave! (01:07:24.235)
                        Yup.</p>
                        <p>Bryan! (01:07:26.889)
                        Anyways, yeah, last thing I said was he goes to this bar to literally feed on the underclasses.</p>
                        <p>Dave! (01:07:33.738)
                        Right, and I think what I think is super interesting about that is that he&#39;s going to do the reverse of that neck a little bit later in the movie, but he seems very uncomfortable in this place. But I think what is interesting.</p>
                        <p>Bryan! (01:07:49.161)
                        Yeah, because he wanders, he sort of paces and wanders around like this is not an environment that he is comfortable in or used to whatsoever. Because also it&#39;s very tight because the previous scene was very open and it was this palatial estate in upstate New York and you know, suddenly here he is in the city and he&#39;s like in this little room with like all these other dudes who do not talk like him.</p>
                        <p>Dave! (01:08:00.214)
                        Yes.</p>
                        <p>Dave! (01:08:11.99)
                        No, and I think it&#39;s also, this I think is where you start to see some of Gunn&#39;s limitations as a filmmaker. Because he makes a lot of choices directorially here that probably work better on the stage. You know, like he&#39;s, so the sex worker kind of comes over to him and he&#39;s sitting at the table, and she&#39;s standing like directly in front of the camera and keeps walking back. And again, it&#39;s that question of accessibility. Yeah, and it&#39;s like you can&#39;t quite see him. It&#39;s that thing again, but it&#39;s just in this scene.</p>
                        <p>Bryan! (01:08:34.229)
                        Oh, she keeps pacing back and forth. Yeah.</p>
                        <p>Dave! (01:08:41.291)
                        It&#39;s more annoying than effective.</p>
                        <p>Bryan! (01:08:44.845)
                        Yeah. Also, to the point of the how much of this was improvised, the sole scene is set up with like a pimp talking to one of his girls. And the extent of their conversation is like, so when are you going to get my money? And she&#39;s like, I&#39;m going to get it for you. Don&#39;t worry, I&#39;m gonna get it for you.</p>
                        <p>Dave! (01:08:59.87)
                        And it goes on. It&#39;s just the shit goes on for so fucking long where it&#39;s like, oh my God, move on. We get it. But also there&#39;s this is also really interesting because it&#39;s he wants to he wants to be closer to this sort of ancestral culture, but he can&#39;t even fit in with the present day black culture. He&#39;s so out of place among people like him because he spends so much of his time with white, you know, upper class white people. So when he goes here, he&#39;s just</p>
                        <p>He says out of place here as he is everywhere else.</p>
                        <p>Bryan! (01:09:32.113)
                        Yeah, because there are these there are these motifs that are going to play through visually of their dreams or visions that has has, which is there&#39;s the Queen and then there&#39;s that sort of like the museum director wearing like a sparkly domino mask. And they&#39;re always like beckoning toward him. And so, yeah, so like this is.</p>
                        <p>Dave! (01:09:49.226)
                        Mm hmm. And he&#39;s even like he it seems like he&#39;s even put off because they you know, they just jump ahead. He and this sex worker like go up to either a room or apartment or whatever above the bar. And she takes off her wig. And she&#39;s sort of like, oh, I can just sort of let myself you know, I can be myself here with this guy because I don&#39;t have to, you know, put up appearances or whatever. And he seems like put off by it.</p>
                        <p>Bryan! (01:10:03.625)
                        Yeah.</p>
                        <p>Bryan! (01:10:14.021)
                        Right, he touches her hair, her real hair in a weird way, almost like it&#39;s alien to him. It&#39;s so it&#39;s so strange. But yeah, so anyways, while they&#39;re there, like they&#39;re gonna start to get down and her pimp suddenly attacks, like ambushes them. And he stabs has several times to no effects. The prostitute shoots him three times, and nothing happens and then has kills them both and is horrified by what he&#39;s done.</p>
                        <p>Dave! (01:10:18.854)
                        Mm-hmm. Yep.</p>
                        <p>Bryan! (01:10:44.809)
                        So now we&#39;re entering part three, which is called letting go. So now at some point later on has his contacted on the phone by a woman who claims to be made his wife according to call.</p>
                        <p>Dave! (01:10:55.879)
                        She is amazing and incredible.</p>
                        <p>Bryan! (01:10:59.25)
                        What?</p>
                        <p>Bryan! (01:11:08.572)
                        Ugh.</p>
                        <p>Bryan! (01:11:15.781)
                        I might.</p>
                        <p>Bryan! (01:11:19.633)
                        Yeah, I&#39;ll hang tight here. You can come back.</p>
                        <p>Dave! (01:11:49.905)
                        Hmm. This here? Fucking internet. The problem now is that I can&#39;t change the echo cancellation, but whatever.</p>
                        <p>Bryan! (01:11:50.241)
                        Ayyy</p>
                        <p>Bryan! (01:11:57.437)
                        whatever. Okay, so I said, now we&#39;re entering part three, letting go. Now, at some point later on, that is annoying. I can hear the echo. You know, it is river now because it&#39;ll be on the transcript.</p>
                        <p>Dave! (01:12:09.265)
                        What? Yeah.</p>
                        <p>Dave! (01:12:18.941)
                        What are they gonna flag you for insubordination?</p>
                        <p>Bryan! (01:12:21.905)
                        River Side&#39;s racist. They don&#39;t always talk about black people. Now at some point later on, Hess is contacted on the phone by a woman who claims to be Maeda&#39;s wife. Oh my God, yes, according to the call, Maeda has been missing now for six months. Hess simply tells her that Maeda has disappeared. This isn&#39;t a surprise for this caller since Maeda is crazy. She asked to come and stay with Hess for a little while and then she says the fucking best line in the</p>
                        <p>Dave! (01:12:30.853)
                        She is glamorous as fuck.</p>
                        <p>Dave! (01:12:44.849)
                        I think that&#39;s for a little while. And then she says the fucking best line of the entire movie. Yeah. I&#39;m standing in front of Pan-American. The driver can&#39;t miss me because I&#39;m valuable. Yes. I am going to say that to everyone everywhere I go. They&#39;ll be like, oh, here&#39;s the number for when we bring the food to the table. And I&#39;ll be like, no, no. Don&#39;t worry about it. You&#39;ll know what it&#39;s for because I&#39;m valuable.</p>
                        <p>Bryan! (01:12:50.205)
                        I&#39;m standing in front of Pan American and the driver can&#39;t miss me because I&#39;m valuable.</p>
                        <p>Bryan! (01:13:00.167)
                        It&#39;s pretty fucking awesome.</p>
                        <p>Bryan! (01:13:09.577)
                        Yeah. So when she arrives, she sees Hess waiting for her and says, tell your boss I&#39;m here, which we are planting a flag in</p>
                        <p>Dave! (01:13:17.833)
                        And this, so this is when we start to get, yeah, this is the class stuff we&#39;re gonna get into because she is very class oriented.</p>
                        <p>Bryan! (01:13:26.925)
                        So inside they have a drink together and just like before with Mada, Ganja has this little monologue that&#39;s like Mada&#39;s is a little crude. It&#39;s about a dude smuggling weed in from Mexico by stuffing it up his butt. Just like before, Hess is aloof and unimpressed, but they immediately become smitten with one another.</p>
                        <p>Dave! (01:13:44.907)
                        Now, I wonder if this the whole point of this story is to sort of show that she is she&#39;s not like him. She&#39;s just sort of like a normal kind of down to earth hip chick.</p>
                        <p>Bryan! (01:13:59.121)
                        Yeah, she&#39;s but also like she&#39;s got agency. She&#39;s headstrong. It&#39;s hard not to like her. Also, like in spite of the fact that she is</p>
                        <p>Dave! (01:14:08.193)
                        Oh, yeah, no, she&#39;s a she&#39;s a fucking monster throughout the whole movie. And she is the most likable, incredible character in the whole film. It&#39;s also worth saying she&#39;s very different presentation, not just from I think, the way women are portrayed in black exploitation, or in black movies at the time, but also the way black women are portrayed in this movie, because she is a sharp contrast from the sex worker from earlier.</p>
                        <p>Bryan! (01:14:15.857)
                        Yeah.</p>
                        <p>Bryan! (01:14:31.786)
                        Yeah.</p>
                        <p>Bryan! (01:14:36.305)
                        Yeah, one of the comments from her that I had read in some of some of the articles was like, she&#39;s not like black women in other sort of black movies where they&#39;re either sort of like a trophy to be one or they&#39;re like a superhero like coffee or Cleopatra Jones. She&#39;s not like either of those characters at all like she&#39;s very unique in that way.</p>
                        <p>Dave! (01:14:59.429)
                        Yeah, she is just a woman who will not be fucked with.</p>
                        <p>Bryan! (01:15:03.893)
                        This is true because she&#39;s valuable. Now it appears for a moment that he&#39;s going to attack her, but can&#39;t bring himself to and he runs away hiding in the attic where he drinks some of his stored blood. And then later Ganja finds him there and they resume having sex.</p>
                        <p>Dave! (01:15:05.77)
                        Mm-hmm.</p>
                        <p>Dave! (01:15:16.797)
                        Later.</p>
                        <p>Bryan! (01:15:23.601)
                        Not long after this, they fall into an easy domestic situation. Their relationship dynamic is very interesting. As we said, Ganges Manor is no bullshit, seems to challenge Hess in a way.</p>
                        <p>Dave! (01:15:24.309)
                        after this they fall into an easy domestic situation. Relationship dynamics.</p>
                        <p>Bryan! (01:15:37.649)
                        Now, I believe this is actually the part where they&#39;re like having breakfast. And this is when she&#39;s like, starts to be, she&#39;s real shitty with Archie.</p>
                        <p>Dave! (01:15:43.993)
                        Yes.</p>
                        <p>Dave! (01:15:48.417)
                        Yeah, this scene is really hard to watch because I think she calls him Darky at one point.</p>
                        <p>Bryan! (01:15:55.337)
                        God damn. Yeah.</p>
                        <p>Dave! (01:15:56.345)
                        Which is real fucking rough.</p>
                        <p>Bryan! (01:15:59.761)
                        Yeah. So elsewhere in the city.</p>
                        <p>Dave! (01:16:01.189)
                        Because what she&#39;s doing, she&#39;s going out of her way to distance herself from him. You know, he is the help. He is this sort of lower class of person, and she will absolutely not be treated that way by anybody. But in order to do that, she really goes out of her way to belittle him in a way that is so uncomfortable and so cruel. And it&#39;s like, I really I love this character, but you&#39;re being a fucking cunt right now.</p>
                        <p>Dave! (01:16:32.301)
                        Womp</p>
                        <p>Bryan! (01:16:34.601)
                        I know this is fucking frustrating as hell, man. We&#39;re going to get through it for fuck&#39;s sake.</p>
                        <p>Dave! (01:16:42.804)
                        I can&#39;t think of anything else I can do here.</p>
                        <p>Bryan! (01:16:45.469)
                        Hmm.</p>
                        <p>Dave! (01:16:46.905)
                        I mean, I&#39;ve shut everything else down, so...</p>
                        <p>Bryan! (01:16:52.991)
                        Yeah.</p>
                        <p>Dave! (01:16:55.085)
                        Can you try restarting? We already did.</p>
                        <p>Dave! (01:17:00.157)
                        Do you want me to restart the thing? It says it&#39;s... No, that&#39;ll take forever.</p>
                        <p>Dave! (01:17:08.761)
                        Well, fuck, we can&#39;t abandon it halfway through.</p>
                        <p>Bryan! (01:17:12.018)
                        it. I know it would suck to go back and start this over.</p>
                        <p>Bryan! (01:17:19.549)
                        I&#39;m just going to keep going. So elsewhere, elsewhere in the city has spots and other prostitutes standing out on the stoop of an apartment building with a baby. Back home.</p>
                        <p>Dave! (01:17:23.71)
                        All right. Now, most notably, she is she is a white prostitute. Yes. So this is the flip side of the earlier scene where, you know, he is sort of at this overwhelmingly white party and goes out afterwards and kills this.</p>
                        <p>Bryan! (01:17:32.913)
                        Yes. Yeah, it&#39;s a pretty interesting detail. So back at home ganja</p>
                        <p>Dave! (01:17:46.985)
                        this black sex worker. Now he watches as Ganja sort of verbally abuses and is cruel towards Archie, this you know, black butler of his. And his reaction is to then go out and kill a white person.</p>
                        <p>Bryan! (01:18:03.581)
                        And yeah, so she is still being like really shitty to Archie. She she wants to find the wine cellar. She knows there&#39;s a wine cellar there. So she&#39;s prowling around in the basement looking for it when she finds the body of her dead husband on ice. She screams in horror.</p>
                        <p>Now elsewhere, Hesse has murdered the prostitute for her blood. The camera angle tilts as her baby screens in the background.</p>
                        <p>Dave! (01:18:24.125)
                        elsewhere.</p>
                        <p>Dave! (01:18:34.345)
                        that this part feels a little bit of all the movie, because obviously this is, I said, this is not a horror movie. It never feels like a horror movie. This is the one moment that feels a little bit like, oh, is it good? Is he going to do something terrible? It&#39;s like, oh, come on. What is this? Whose idea was this?</p>
                        <p>Bryan! (01:18:51.709)
                        Yeah, I know. But there were private there were moments in that when I was like, Oh shit, is he gonna fucking kill that baby? He doesn&#39;t he does not</p>
                        <p>Dave! (01:19:00.417)
                        which I mean, it would have been so out of step with absolutely everything else in the movie. It just feels so unnecessary. Like the white sex worker part of it is, I think, important to the story. The baby part is a little bit unnecessary and it feels like it&#39;s just sort of poking a little bit at that. Like, OK, you wanted a fucking horror movie. Here you go kind of thing where it&#39;s like it&#39;s so half hearted and unnecessary. You could have just left it out.</p>
                        <p>Bryan! (01:19:21.012)
                        Hey.</p>
                        <p>Bryan! (01:19:25.333)
                        Yeah. But now at dinner, ganja confronts Hess about her husband&#39;s body in the basement. But Hess won&#39;t engage. That leads to a long monologue from ganja about her childhood and how her mother sucked. It is an unbelievably</p>
                        <p>Dave! (01:19:39.65)
                        This part is probably one of, I think the bathtub suicide scene is probably one of the best parts. I think this is also one of the best parts of this movie.</p>
                        <p>Bryan! (01:19:49.549)
                        unbelievably compelling, extremely dark story.</p>
                        <p>Dave! (01:19:51.205)
                        because this tells you everything you need to know about Ganja as a character.</p>
                        <p>Bryan! (01:19:56.497)
                        Yeah. So what it&#39;s the story is basically about how she grew up in Boston, where they had they had, you know, it snows and they would have these snowball fights out in the street and she was the best at making snowball fall. But it was just her, all these kids in the neighborhood playing and her but somebody in the neighborhood told her mother that she was being chased around by a boy.</p>
                        <p>Dave! (01:20:07.629)
                        She didn&#39;t mention the turkeys though, which is I think weird, but you know, whatever. I didn&#39;t write the movie. It&#39;s fine.</p>
                        <p>Bryan! (01:20:26.073)
                        and their mother would like basically calls her like a slut for this.</p>
                        <p>Dave! (01:20:32.442)
                        and she slaps her.</p>
                        <p>Bryan! (01:20:33.809)
                        And and she slaps her and then but also at the same time, there&#39;s a detail about her brother. She has brothers and sisters who are all like significantly older than her. And so it was like always very heavily implied throughout her life that she was an accident. And so.</p>
                        <p>Dave! (01:20:52.533)
                        What I think about that this is what&#39;s so important about this is that her mother&#39;s attitude towards her, I think she kind of extrapolates that and understands like this is how the world is going to treat you. This is how the world will always see you. No matter what you do. You are a woman and you are a black woman. And one of the ways you can sort of read that is that her mother is sort of trying to prepare her for the world.</p>
                        <p>by hardening her because that is who she will become from that point on because she essentially says, from this moment on, I realized I was the only one who was ever going to take care of myself like I was ganja was always going to have to take care of ganja and nobody else was going to do that ever. And that defines who she is as a person. It&#39;s why she&#39;s cruel to Archie. It&#39;s why she does what she does at the end. It&#39;s why she does everything that she ever does in her life is like</p>
                        <p>I will always whenever the world has a chance, it&#39;s going to fuck me. So I have to fight as hard as I can because nobody else is ever going to fight for me. And that is who she is as a character. And there&#39;s something admirable because you don&#39;t see that a lot. You don&#39;t see it in characters, in sort of female characters in movies in general, even though that is so much of like marginalized people&#39;s experience of the world, whether you are a woman, whether you are a black person, whether you&#39;re gay, like.</p>
                        <p>Bryan! (01:21:58.173)
                        Yeah.</p>
                        <p>Dave! (01:22:17.405)
                        The reality is the world is going to treat you badly. At least that&#39;s the way it used to be. I&#39;m not sure if it&#39;s still the way it is, but it probably is. But that&#39;s the reality. And so I think it resonates so much with, listening to this model, I was just like, God damn, that is fucking amazing. And she delivers it so beautifully and so perfectly.</p>
                        <p>Bryan! (01:22:40.093)
                        Yeah, yeah. It&#39;s she turns out to be like a social climber, but in a way that doesn&#39;t seem I mean, it doesn&#39;t it&#39;s not it&#39;s not admirable. But it&#39;s not. It makes sense. It would be in other in other characters in other movies, she would be a bad person because of this. And she&#39;s not great. But you understand her position. Because what ends up happening</p>
                        <p>Dave! (01:22:51.95)
                        It&#39;s a way that makes sense.</p>
                        <p>Dave! (01:23:04.197)
                        But it also I think it points to that nuance of character. Like it&#39;s just like this is, you know, he&#39;s not going to give you characters that are simple, you know, even whether it&#39;s George Mata, whether it&#39;s, you know, Hess, because, you know, Hess Green is kind of he&#39;s a kind of a shitty character. I mean, none of the characters in this are particularly likable, but they&#39;re all put in these really. Yeah, he&#39;s great. I&#39;ve got to know about him at the end. But like they&#39;re all put in these situations where it&#39;s just like.</p>
                        <p>Bryan! (01:23:23.115)
                        Archie is.</p>
                        <p>Dave! (01:23:30.669)
                        you start to realize they&#39;re both just trying to get by and not feel terrible about who they are and where they are. And I think that&#39;s why you can look at them and just be like, okay, yeah, I get that she&#39;s kind of, she&#39;s not kind of mean, she&#39;s really mean. But you understand why she&#39;s mean, because the world is also very mean, and they&#39;re just trying to get by. And I think that this, this monologue she gives is very important to the things that happen next, because I think</p>
                        <p>Bryan! (01:23:39.571)
                        Yeah.</p>
                        <p>Dave! (01:24:00.757)
                        It changes the way that Hess sees her as a person and sort of symbolically in the moment.</p>
                        <p>Bryan! (01:24:08.242)
                        you. Yeah, because she immediately casts off meta like</p>
                        <p>Dave! (01:24:12.217)
                        Oh yeah, she&#39;s over it. She&#39;s just like, who? Oh, nevermind. You know what? It wasn&#39;t good. It wasn&#39;t working out very well anyway. He was crazy. It&#39;s fine. Don&#39;t worry about it.</p>
                        <p>Bryan! (01:24:15.09)
                        Yeah.</p>
                        <p>Bryan! (01:24:20.141)
                        Yeah, has. But has offers her a stability that made it could not. He was insane. has a deep flaw that she doesn&#39;t yet know about but taking up with him puts her in a better position than she wasn&#39;t before, which explains why she&#39;s willing to let the death of her husband slide.</p>
                        <p>Dave! (01:24:36.769)
                        So I think the way that she the way that after she tells this story. Because it has is so conflicted and he&#39;s so torn between these worlds and he&#39;s so desperate to access this sort of liberated part, because I think that&#39;s what this that&#39;s sort of the African ancestry represents is this sort of true liberation from the kind of white oppression, you know, that is the other side of his world.</p>
                        <p>And I think my interpretation of this is that he starts to see her as a sort of fully liberated character. She is she is not beholden to white culture. She&#39;s not beholden to white religion. She&#39;s also not beholden to black heritage. She&#39;s just she is just ganja and she will do whatever the fuck she has to do and wants to do in order to get by. And I think that is why he starts to see her as this sort of alternate form of liberation, because he says to her.</p>
                        <p>Bryan! (01:25:19.368)
                        Yeah.</p>
                        <p>Dave! (01:25:29.677)
                        I want you to, or he says something like, I want you to stay, I want to be with you forever. By which he means, I want you to live forever. I think he actually says, I want you to, I want to live with you forever. And so he wants to, he wants to sort of like, he, he sees her as she is the way out. She is the true form of liberation.</p>
                        <p>Bryan! (01:25:34.981)
                        Yes.</p>
                        <p>Bryan! (01:25:39.741)
                        Yeah, yeah, he says I really want you to live forever.</p>
                        <p>Bryan! (01:25:48.805)
                        Right. And I wonder if that&#39;s the reason that she because this is the only part in the movie that she does this she refers to herself in the third person, which kind of places her as a subject of her own monologue it&#39;s very weird it makes it. It. Yeah, it may kind of like makes her an object of its own that&#39;s not really connected to the sort of broader tapestry of the movie.</p>
                        <p>Dave! (01:26:03.085)
                        It&#39;s very effective though, it&#39;s very effective.</p>
                        <p>Dave! (01:26:13.893)
                        But it makes the, it&#39;s the child version of her that is the object of the sentence. It&#39;s not so much, it&#39;s sort of the abstract idea of this person that is Ganja. Not her in the present, it&#39;s more just like, I knew that I had to take care of this thing, and I was the only one who was gonna do it. Because what happens after this is the only time in the movie where you see Hess experience any kind of joy.</p>
                        <p>Bryan! (01:26:24.722)
                        Yeah.</p>
                        <p>Bryan! (01:26:40.818)
                        Yes, so-</p>
                        <p>Dave! (01:26:40.913)
                        Or really either one of them. I mean, she seems to delight in sort of her cruelty a little bit, but it&#39;s in this moment right after that you actually see both of them start to experience joy. It&#39;s the only time they&#39;re going to do it throughout the whole movie.</p>
                        <p>Bryan! (01:26:53.277)
                        Yep, because following this Hesse and Ganja play in the house, in a way which reflects the story that she told about how her mother slapped her for being seen out in the neighborhood being chased by a boy while having a snowball fight. They&#39;re not having a snowball fight. It&#39;s more like a pillow fight where they just like throwing shit at each other. But they&#39;re having a really, really good time together. And it&#39;s very childlike and innocent.</p>
                        <p>Dave! (01:27:12.409)
                        Now what I loved about this particular moment is that the camera, and they move the camera a lot in this whole scene. It gets moved around a lot.</p>
                        <p>Bryan! (01:27:19.997)
                        The camera is in a fixed position and they are off screen having a blast.</p>
                        <p>Dave! (01:27:24.485)
                        That I love the fact that you cannot see. It&#39;s again, it&#39;s that question of accessibility. It&#39;s, you know, we are not, we can&#39;t see all of this. We can&#39;t, like, you know, it&#39;s like the conversations before, we can hear it and we know it&#39;s happening, but we can never see all of what&#39;s happening. It&#39;s always off screen.</p>
                        <p>Bryan! (01:27:42.001)
                        Yeah. So shortly after this, they&#39;re married. In bed that night, Hess reveals his secret implying that he wants her to come with him. He uses the knife to stab her and shortly thereafter, she&#39;s a vampire too. But her memory of this is that it was a dream and she&#39;s immediately haunted by a hunger that she can&#39;t satisfy. And now we&#39;re going to loop back into what might be a dream or a vision of</p>
                        <p>basically them out in that field again where Hess stabs her but her body is when we when he eventually what happens is like the stabbing takes place off screen and it&#39;s just him kind of like sitting over her body in bed but then we flash to this kind of like vision dream situation where he&#39;s actually stabbing her but they&#39;re outside like in that kind of wild field from before.</p>
                        <p>Dave! (01:28:13.991)
                        Mm-hmm.</p>
                        <p>Dave! (01:28:32.593)
                        But what&#39;s interesting about the field is that this kind of wedding, maybe not happening in his mind or whatever, the one that&#39;s happening in the field, it&#39;s this particular wedding between the two of them that involves the stabbing, is taking place in the same location that he is constantly seeing the Queen of Merthia or the women running through the field. It&#39;s all of that happening. And then when they go back to the next scene where they have the actual wedding,</p>
                        <p>It is where they have the party earlier with all of the white people. So he has this sort of like spiritual wedding. Yeah. And that wedding is a downer.</p>
                        <p>Bryan! (01:29:06.321)
                        Yeah, and like the attendees are also white.</p>
                        <p>Bryan! (01:29:14.841)
                        Yeah. Yeah, it is. Because again, that comes right on the heels of the scene of them just playing together. And then now here they are stone faced in this kind of like kind of shotgun wedding almost in the in the backyard.</p>
                        <p>Dave! (01:29:20.295)
                        Mm-hmm.</p>
                        <p>Dave! (01:29:26.053)
                        because they&#39;re back in that world. So they&#39;re, you know, in the moment where they&#39;re sort of chasing each other around the house in this sort of joyful moment, is this liberated freedom. And now they&#39;re, and then they have this sort of wedding in the field that I think has a connection to that sort of ancestral Murthian culture. And then they&#39;re back in this kind of white colonial Western religion getting married in this very kind of rigid ceremony.</p>
                        <p>It&#39;s a sharp contrast all the time.</p>
                        <p>Bryan! (01:29:56.302)
                        Yeah.</p>
                        <p>Bryan! (01:29:59.517)
                        Yep. So now Hess gives Ganja blood, which she is at first horrified by, but immediately settles into. So that night, they have a friend over for dinner, figuratively and literally. And then Ganja, Ganja murders him and then feeds on him while they have sex.</p>
                        <p>Dave! (01:30:16.651)
                        Mmm. Good. Zing.</p>
                        <p>Bryan! (01:30:26.897)
                        And it&#39;s a very long scene too. When they dispose of the body, her victim still appears to be breathing.</p>
                        <p>Dave! (01:30:34.894)
                        So I didn&#39;t understand this part because she keeps saying he&#39;s still breathing and has to sort of dragging her away because they just kind of dump him in a field and has this kind of dragging him away as though he keeps saying like just don&#39;t like basically don&#39;t worry about it ignore it is he won&#39;t be for long kind of thing.</p>
                        <p>Bryan! (01:30:50.741)
                        Mm hmm. Yeah, because you do see him breathing like the he&#39;s like wrapped in plastic and like the body is moving. It&#39;s going to come this is going to. He will come back later. Back at home, Hess reads from a record of the Murthians, which reveals again, the only way that they can be killed. They must stand within the shadow of the cross against their heart in order to be destroyed.</p>
                        <p>Dave! (01:30:55.782)
                        Yes.</p>
                        <p>Dave! (01:31:01.721)
                        And that&#39;s not for nothing, because he will come back later.</p>
                        <p>Bryan! (01:31:22.121)
                        And so the next day, maybe, Hess walks into the church of his driver as mass is going on, and he appears to have a very powerful religious experience there.</p>
                        <p>Dave! (01:31:32.269)
                        This scene is, I think it is the, you know, the suicide at the beginning, Ganja&#39;s monologue and this are, these are the three profoundly impressive moments of this movie. Because Dwayne Jones fucking kills it in this scene.</p>
                        <p>Bryan! (01:31:45.453)
                        right because also this scene is it&#39;s unreal like this is the thing with Dwayne Jones is like really the only thing that I think anybody and a lot of people are familiar with him because fucking by the living dead goddamn classic. Everybody&#39;s seen it and he&#39;s great in that. But it&#39;s not really enough to give you sort of a sense of his of his abilities as an actor because again he&#39;s kind of working with the material that he&#39;s given.</p>
                        <p>Here, he&#39;s given so much fucking room to just spread out and do his thing. And this is a this is the moment where he really fucking flexes because he&#39;s very, very cold.</p>
                        <p>Dave! (01:32:21.309)
                        But yeah, but even in this movie though, he&#39;s a very kind of aloof, standoffish character. He doesn&#39;t show a lot of emotion. And I think that&#39;s intentional. He&#39;s supposed to be this very closed off, isolated person. And then you get to this part of the movie and he is distraught, but also ecstatic in a way that he is very like, he is not just giving you emotion. He is giving you an outpouring of emotion that you have not seen from this character or any character in the movie until now.</p>
                        <p>And it is it&#39;s very, very powerful.</p>
                        <p>Bryan! (01:32:55.257)
                        And the whole scene is shot, it&#39;s very hectic. It&#39;s shot like a documentary almost, where it&#39;s like, you know, there&#39;s not a lot of framing. It&#39;s just kind of like, point the camera here, point the camera there, people&#39;s feet, people dancing, people in the aisles.</p>
                        <p>Dave! (01:33:09.317)
                        Yes, very intimate. It&#39;s very, very intimate. And again, I think this is also another moment where I was like, this part also feels kind of homoerotic. And I don&#39;t know if it&#39;s just because of the ecstasy, but like, he sort of drops down in front of the, you know, Sam Wayman&#39;s character, whose name I can&#39;t remember, but the preacher, basically. Yep.</p>
                        <p>Bryan! (01:33:18.883)
                        Oh, the, yeah, the.</p>
                        <p>Bryan! (01:33:28.657)
                        Luther, Reverend Luther Williams. Yeah. Yep. And yeah, it&#39;s so he basically like, the implication here is that he&#39;s kind of racked with guilt over the death of this guy, who for the I believe for the first time was somebody that he knew.</p>
                        <p>Dave! (01:33:50.565)
                        But also, I wonder too, is he, he&#39;s obviously rock-skilled about that, but is it also that he has now kind of corrupted Ganja as well? He&#39;s dragged someone else into this, because that&#39;s basically what happens with George Mato at the beginning, is he infects Hess with this and then he dies.</p>
                        <p>Bryan! (01:33:59.487)
                        That&#39;s.</p>
                        <p>Bryan! (01:34:09.681)
                        Yeah. Okay. So, it&#39;s just it&#39;s a pattern of sort of like</p>
                        <p>sacrifice in order to kind of like redeem somebody else maybe</p>
                        <p>Dave! (01:34:22.821)
                        But that&#39;s also the way that I initially read this scene was like this, this tension of trying to be two things and not being satiated by either one of them becomes too much. Just living in that tension becomes too much. And those pieces are too irreconcilable. And so in the end, he sort of just goes back to the one that he knows, the one that is most familiar.</p>
                        <p>And in doing so, what that means in this is essentially it&#39;s a kind of like a spiritual death. And in this, it&#39;s a literal death. But it just he just sort of resigned to I cannot live this way. I cannot live in this sort of torn identity. And so he decides to basically just kill himself.</p>
                        <p>Bryan! (01:34:57.213)
                        Yeah.</p>
                        <p>Bryan! (01:35:07.313)
                        Yeah, yeah. The placement of Christianity in the movie is also kind of fascinating. And I&#39;m not really sure where Gunn falls in sort of in his feelings of Christianity. The implication here is that there&#39;s a whole segment of the black population that is kind of just assimilated into a religion, a kind of cultural experience.</p>
                        <p>Because religion and culture are inextricably linked. Like it&#39;s very much a part of the place where you live. And this religion is...</p>
                        <p>Dave! (01:35:40.897)
                        I mean, especially because in his case, I mean, and in the case of black Americans, it is it is defined who they are. I mean, it is it is the thing that drove sort of the empire to expand. It is the thing that drove that colonization religion was the thing that told them it was OK to do that. And so to participate in that religion is sort of like, well, how can you be?</p>
                        <p>this person with ancestral roots in this very old thing that you&#39;re now discovering, while also participating in this thing that is fully representational of the colonizer. It&#39;s impossible to be both. That&#39;s what I was saying at the beginning. It&#39;s like, it&#39;s impossible to be both of these things. And yet, within these single individuals, they have to reconcile these two things. And I think for Hess, he can&#39;t do that. And in the end, it becomes too painful.</p>
                        <p>Bryan! (01:36:31.309)
                        Yeah, that&#39;s got to be it because really because they&#39;re what we see is Hess struggles with this very much. And he like again, he&#39;s on his own throughout much of the movie until ganja shows up and you know, they have this very, very brief, very intense kind of affair. But it&#39;s juxtaposed with the scenes of essentially a black community in a church.</p>
                        <p>that is sort of represents the sort of the colonizer and the oppressor, but all of these people just seem kind of fine with it. And it&#39;s, it does, there does seem to be a lot of judgment from, from gun in that respect.</p>
                        <p>Dave! (01:37:08.989)
                        But I mean, the same could be said for the, you know, where he goes to the bar as well, where it&#39;s like, here is another part of black culture that he doesn&#39;t understand and does not fit into. And so it&#39;s like, you don&#39;t belong here, you don&#39;t belong there, where do you belong? Because at the bar, they&#39;re also just sort of caught up in, you know, in sex and in drinking, and there&#39;s no, there&#39;s no interest in looking deeper than that. So he doesn&#39;t belong in any of these places. And so he&#39;s sort of at the end resigned to</p>
                        <p>I guess this is it. I guess there&#39;s no, there&#39;s nowhere for me. And you know, to live with ganja means to live in this sort of violent tension all the time in order to stay alive.</p>
                        <p>Bryan! (01:37:47.558)
                        Yeah, because now they do have to kill in order to survive.</p>
                        <p>Dave! (01:37:51.749)
                        Because as he walks, now when he walks out of the church, is he blading?</p>
                        <p>Bryan! (01:37:56.582)
                        No, he&#39;s sweating.</p>
                        <p>Dave! (01:37:58.001)
                        Cause he&#39;s like dripping something on the, yeah, he is sweaty as fuck and it&#39;s incredible, but he&#39;s like dripping something on the carpet behind him as he walks out.</p>
                        <p>Bryan! (01:38:01.973)
                        I&#39;m out.</p>
                        <p>Bryan! (01:38:06.329)
                        I think it&#39;s I think it&#39;s he&#39;s just he&#39;s just sweaty. But. He returns home. And he stands in the shadow of a cross asking Ganja to come with him. And it&#39;s a another crazy scene, it&#39;s almost like the scene of where Maeda was outside with the with the noose hanging because it&#39;s just it&#39;s a room in this house somewhere, but it&#39;s all just blackness and there&#39;s a single.</p>
                        <p>Dave! (01:38:31.213)
                        and it&#39;s lit with a hanging bulb.</p>
                        <p>Bryan! (01:38:33.157)
                        the hanging bulb and a cross that is also hanging.</p>
                        <p>Dave! (01:38:37.217)
                        And so I think this is another example of this is where kind of guns limitations as a director, as a film director really come into play because this feels like something that would probably feel very effective in like a black box theater or maybe a larger theater, but it doesn&#39;t so much work on film. It feels a little cheap and it like kind of undermines the scene a little bit because it&#39;s a very powerful moment.</p>
                        <p>Bryan! (01:38:59.269)
                        Yeah. But now, yeah, yeah. And this is the moment where Hess dies. And so now Ganja has his body taken away by an ambulance, and she definitely does not follow him. She chooses to live in as much as of Empire Can Live.</p>
                        <p>Dave! (01:39:12.537)
                        Because Ganja takes care of Ganja. She can&#39;t, she&#39;s not there for him. She&#39;s gotta do what she&#39;s gotta do, yeah.</p>
                        <p>Bryan! (01:39:17.757)
                        there for ganja. Yeah. So she returns to the house. She looks out the window into the back garden. And she sees the man that they murdered emerging from the swimming pool running toward</p>
                        <p>Dave! (01:39:27.745)
                        In a very like Phoebe Cates from Fast Times at Ridgemont Highway, like he just comes like, he is a roiling sea and then he comes bursting out and he&#39;s fully nude running towards the house.</p>
                        <p>Bryan! (01:39:32.379)
                        Hahaha!</p>
                        <p>Bryan! (01:39:39.529)
                        DONG! Just flop it all over the place. Ganja looks at the camera and she smiles.</p>
                        <p>Dave! (01:39:47.153)
                        And as he jumps over, as he reaches the house, he jumps over Archie&#39;s dead body. And I thought, come on, man, you didn&#39;t have to kill Archie. He&#39;s the only cool person in the movie.</p>
                        <p>Bryan! (01:39:54.309)
                        Ha ha.</p>
                        <p>Bryan! (01:39:58.845)
                        Yep, but Ganja did not like Archie. No. So yeah. Yeah. We cut to a scene of some black children. Uh, and then we roll credits.</p>
                        <p>Dave! (01:40:02.289)
                        And that&#39;s the fucking end of this movie.</p>
                        <p>Dave! (01:40:11.149)
                        I, you know, do I like this movie? Hmm, I don&#39;t know if I like it. I think there is so much to admire about this and there&#39;s so, it is very, very powerful in a lot of ways, especially the themes that it&#39;s dealing with and the time that it&#39;s trying to do it. It&#39;s really something to take in.</p>
                        <p>Bryan! (01:40:33.125)
                        It&#39;s extremely challenging. Like this is not a, this is not a, this is the part where we would talk about the sort of relevance and shit. This movie is relevant as fuck. I believe that.</p>
                        <p>Dave! (01:40:34.991)
                        Mm-hmm.</p>
                        <p>Dave! (01:40:41.405)
                        Well, so that&#39;s I did actually I did kind of write up some conclusions and my sort of the way where I landed at the end was, you know, these themes of isolation and of conflicting identities. Maybe I don&#39;t know if they&#39;re as strong today as they were in the 70s. I kind of get the feeling maybe they are or maybe even more so. But like, I think as long as those are relevant, this movie is relevant. But it&#39;s also it&#39;s presented in a way that is. It&#39;s avant garde. Yeah.</p>
                        <p>Bryan! (01:41:04.377)
                        Yeah, it&#39;s it was super. I was just saying, super potent in the moment. Yeah.</p>
                        <p>Dave! (01:41:13.357)
                        And so I don&#39;t know that it&#39;s still like to two audiences now doesn&#39;t work. It&#39;s a very, very challenging movie is visually challenging. It is thematically challenging. And I think because of that, it will always be it will always be a cult movie just because you know, experimental film is just never going to be a mainstream thing. But I think thematically, it&#39;s very powerful. It&#39;s very compelling. And the</p>
                        <p>A lot of the acting in it is very, very good. Some of the writing is a little bit up its own ass at times, but for the most part, I think it is a, it&#39;s something you should see at least once and really take some time to think about it because there&#39;s a lot that&#39;s here to really kind of unpack, I guess, for lack of a less lame term.</p>
                        <p>Bryan! (01:41:58.797)
                        Yeah, I can&#39;t say that I really enjoyed it. But it&#39;s I found the entire process of exploring it and researching it interesting as fuck. It gave me a lot to think about. It is an incredibly sad movie. It is a document of despair in a way that very few movies are like it is a movie about a truly lonely existence.</p>
                        <p>Dave! (01:42:23.673)
                        Yeah, I mean, it is that was I think my the primary feeling I had when I turned it off was like that is it was profoundly sad. It is a heartbreaking story about someone who just cannot find a way to reconcile all the pieces of himself in a way that is fulfilling or satisfying. And his only way kind of to deal with it is essentially through death. Like he can only he can only get out that way. That&#39;s the only way he will be.</p>
                        <p>kind of made whole and it&#39;s tremendously heartbreaking, but it is it&#39;s definitely worth seeing I Don&#39;t know that it&#39;s the kind of thing you put on more than once though, because it&#39;s a lot</p>
                        <p>Bryan! (01:43:07.565)
                        Yeah, I can&#39;t see myself really going out of my way to watch it again. Like if I if there were if there was ever anybody who was like interested in sort of taking the journey, I would certainly go there with them. But I would need a reason to come back to this one for sure. Like I watched it.</p>
                        <p>Dave! (01:43:21.809)
                        But I think that&#39;s also that&#39;s what he&#39;s trying to do. I mean, that is essentially what Bill Gunn was initially trying to do was to say, here is my experience of the world. Here&#39;s where I&#39;m at right now. And here is where I guess a lot of people are at right now trying to do this. And I think the fact that so many critics came back and they were like, what the fuck is this? These aren&#39;t vampires. It&#39;s that must have been really, really disappointing and really frustrating and probably very hurtful to be like, I put everything I had into this story.</p>
                        <p>I gave you basically what it is like to be me, how I experience the world, and they kind of looked at it and were like, what the fuck, dude?</p>
                        <p>Bryan! (01:43:57.297)
                        Yeah, where&#39;s the where&#39;s the vampires guy?</p>
                        <p>Dave! (01:43:59.725)
                        Yeah, so that&#39;s why when I hear people like be like, Oh, it&#39;s a vampire movie. I&#39;m like, well, no, it&#39;s not really a vampire movie. Vampire. That&#39;s that&#39;s the metaphor part of it. But like to see it only as that you&#39;re missing so much.</p>
                        <p>Bryan! (01:44:11.821)
                        Right, because the usual sort of vampire metaphors are largely absent from this movie. Like, there&#39;s, you know, there&#39;s the he set out to sort of use vampirism as an addiction. But like in this case, like an addiction to what really? I can see.</p>
                        <p>Dave! (01:44:30.521)
                        It&#39;s more like a desperation.</p>
                        <p>Bryan! (01:44:33.585)
                        Yeah, yeah. Or a yearning a hunger for belonging really is what it is. Yeah, the like that&#39;s another part of the sort of challenging nature of the movie is like, it&#39;s none of the easy metaphors are there. It takes it we I spent so much time just fucking ruminating on this movie. But I mean, I&#39;m glad that we did it because it was it was it&#39;s fucking amazing in ways that</p>
                        <p>Dave! (01:44:36.79)
                        Mm-hmm.</p>
                        <p>Mm-hmm.</p>
                        <p>Bryan! (01:45:01.725)
                        few movies are, but it makes for a very difficult watch.</p>
                        <p>Dave! (01:45:07.409)
                        Yep.</p>
                        <p>Bryan! (01:45:07.697)
                        So, yeah, so usually, usually like we don&#39;t usually do this, but I thought it was really kind of a good idea to actually cite our sources in this one, I just wanted to point out a few of the research sources that I used for this. There is a BBC article called ganja and Hess, the 50 year old vampire movie masterpiece that critics got wrong. There is a get goes in article called cosmic freeze frame a poetics of Bill Gunn.</p>
                        <p>and there&#39;s an article at offscreen.com called the uniqueness of Bill Gunn&#39;s Ganja and Hess that I used.</p>
                        <p>Dave! (01:45:43.76)
                        I did write anything down. I have millions and millions of resources on cultural studies. I&#39;m happy to share them with anybody if you want them. You also have to then take on part of my student loan debt.</p>
                        <p>Bryan! (01:45:59.025)
                        Alright, alright, so Dave, what are we doing next?</p>
                        <p>Dave! (01:46:02.369)
                        No, holy crap. I think I don&#39;t know. I feel like I&#39;ve been watching so many goddamn movies lately. I think we&#39;re doing coming back next week with Bring Me the Axe and the anniversary show and Dawn of the Dead. Fantastic film. Right. And then two weeks after that, we&#39;re coming back with the ninety nine cent rental to talk about rad. A poor choice on my part.</p>
                        <p>Bryan! (01:46:15.057)
                        Yep, yep, yep. And then.</p>
                        <p>Bryan! (01:46:26.768)
                        We&#39;re gonna turn. Take a 180 degree turn from this fucking a heavy art film to talk about rad the fucking BMX movie.</p>
                        <p>Dave! (01:46:36.989)
                        But don&#39;t worry, we&#39;re coming back at some point in the future with more heady art films, and I think it&#39;s going to be a disaster.</p>
                        <p>Bryan! (01:46:45.09)
                        I can&#39;t wait. Be here for this disaster.</p>
                        <p>Dave! (01:46:47.419)
                        Yep.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/my-bloody-valentine">Next Episode</Link></li>
                        <li><Link to="/episodes/x-ray">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)